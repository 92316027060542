import React, { useState, useRef, useEffect } from 'react';
import { T } from 'react-polyglot-hooks';
import { DropdownItem } from 'components/atoms/dropdown';
import {
  closeOnClickOutside,
  bindChannel,
  subscribeToChannel,
  unbindChannel,
  getNotificationTimestamp,
} from 'helpers';
import { useUser } from 'components/util/user-context';
import {
  GetNotificationsQuery,
  GetNotificationsQueryResult,
  NotificationFragment,
  RouteFragment,
  useGetNotificationsQuery,
} from 'graphpl/core';
import { ApolloError } from '@apollo/client';
import { CenterSpinner } from 'components/atoms/loading-spinner';
import {
  NotificationWrapper,
  NotificationTitle,
  NotificationBody,
  NotificationTime,
  StyledDropdownList,
  NotificationBell,
  UnreadDot,
  TitleWrapper,
  BellIcon,
  SeeMore,
  LoadingWrapper,
} from './notification-dropdown.styles';

type RouteStraegy = (args: { payload: Record<string, string> }) => string;

const routeStrategies: Record<string, RouteStraegy> = {
  Match: ({ payload }) =>
    payload?.contestId ? `/matches/${payload.contestId}` : '/matches',
  DirectChallenge: ({ payload }) => {
    if (payload?.challengeId) return `/matches/${payload.challengeId}`;
    if (payload?.contestId) return `/matches/${payload.contestId}`;
    return '/matches';
  },
  Withdrawal: ({ payload: { withdrawalId } = {} }) =>
    withdrawalId ? `/withdraw#withdrawal${withdrawalId}Collapse` : '/withdraw',
  Tournament: ({ payload }) =>
    payload?.tournamentId
      ? `/tournament/${payload.tournamentId}`
      : '/tournaments',
  Lounge: () => '/lounge',
  TransactionHistory: () => '/user/transaction-history',
  GameSettings: () => '/user/game-settings',
  Referrals: () => '/user/referrals',
  StreamerLive: ({ payload }) =>
    payload?.username ? `/twitch/${payload.username}` : `/lounge`,
  Profile: ({ payload }) => (payload?.url ? `/${payload.url}` : '/lounge'),
};

const getLink = ({ name, payload }: RouteFragment) => {
  if (!name) return '/lounge';
  const route = routeStrategies[name];
  if (!route) return '/lounge';

  const link = route({ payload });
  return link;
};

const Notification = ({
  notification: { title, message, createdAt, isRead },
}: {
  notification: NotificationFragment;
}) => (
  <NotificationWrapper>
    <div>
      <TitleWrapper>
        {!isRead && <UnreadDot data-testid="unread-notification-dot" />}
        <NotificationTitle>{title}</NotificationTitle>
      </TitleWrapper>
      <NotificationBody>{message}</NotificationBody>
    </div>
    <NotificationTime>
      {getNotificationTimestamp(parseInt(createdAt || '0', 10))}
    </NotificationTime>
  </NotificationWrapper>
);

type NotificationDropdownProps = {
  notificationsData: GetNotificationsQuery;
  loading: boolean;
  error: ApolloError | undefined;
  fetchMore: GetNotificationsQueryResult['fetchMore'];
  setNotifificationsData: (data: GetNotificationsQuery) => void;
  forwardedRef: React.Ref<HTMLDivElement>;
  isOpen: boolean;
  displaySeeMoreButton: boolean;
  setDisplaySeeMoreButton: (value: boolean) => void;
};

const NotificationDropdown = ({
  notificationsData,
  loading,
  error,
  setNotifificationsData,
  forwardedRef,
  isOpen,
  fetchMore,
  displaySeeMoreButton,
  setDisplaySeeMoreButton,
}: NotificationDropdownProps) => {
  if (error)
    return (
      <StyledDropdownList
        prefixId="notifications"
        forwardedRef={forwardedRef}
        isOpen={isOpen}
      >
        <DropdownItem>Error, please try again!</DropdownItem>
      </StyledDropdownList>
    );
  if (loading)
    return (
      <StyledDropdownList
        prefixId="notifications"
        forwardedRef={forwardedRef}
        isOpen={isOpen}
      >
        <DropdownItem>
          <LoadingWrapper data-testid="notifications-loading">
            <CenterSpinner size={32} />
          </LoadingWrapper>
        </DropdownItem>
      </StyledDropdownList>
    );

  if (!notificationsData) return null;

  const { getNotifications } = notificationsData || {};
  const { notifications, cursor } = getNotifications || {};

  if (((notifications || [])?.length || 0) <= 0)
    return (
      <StyledDropdownList
        prefixId="notifications"
        forwardedRef={forwardedRef}
        isOpen={isOpen}
      >
        <DropdownItem>You have no unread notifications.</DropdownItem>
      </StyledDropdownList>
    );

  const notificationsToRender = notifications?.map((notification) => {
    if (!notification) return null;
    if (!notification.route) return null;
    return (
      <DropdownItem
        href={getLink(notification.route)}
        data-testid="notification-link"
        key={`notification-${notification.title}-${notification.createdAt}`}
      >
        <Notification notification={notification} />
      </DropdownItem>
    );
  });

  return (
    <StyledDropdownList
      prefixId="notifications"
      forwardedRef={forwardedRef}
      isOpen={isOpen}
    >
      {notificationsToRender}
      {displaySeeMoreButton && (
        <button
          type="button"
          onClick={async () => {
            const { data } = await fetchMore({
              variables: {
                cursor,
              },
            });

            const newObject: GetNotificationsQuery = {
              getNotifications: {
                cursor: data?.getNotifications?.cursor || '',
                notifications: [
                  ...(notifications || []),
                  ...(data?.getNotifications?.notifications || []),
                ],
              },
            };
            if ((data?.getNotifications?.notifications || [])?.length < 25) {
              setDisplaySeeMoreButton(false);
            }
            setNotifificationsData(newObject);
          }}
        >
          <SeeMore>
            <T phrase="seeMore" />
          </SeeMore>
        </button>
      )}
    </StyledDropdownList>
  );
};

const NotificationList = React.forwardRef<HTMLDivElement, { isOpen: boolean }>(
  ({ isOpen }, ref) => {
    const [notificationsData, setNotifificationsData] = useState<
      GetNotificationsQuery
    >({});
    const [displaySeeMoreButton, setDisplaySeeMoreButton] = useState(true);
    const { loading, error, fetchMore } = useGetNotificationsQuery({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setNotifificationsData(data);
        if ((data?.getNotifications?.notifications?.length || 0) < 25) {
          setDisplaySeeMoreButton(false);
        }
      },
    });

    return (
      <NotificationDropdown
        loading={loading}
        error={error}
        fetchMore={fetchMore}
        notificationsData={notificationsData}
        setNotifificationsData={setNotifificationsData}
        displaySeeMoreButton={displaySeeMoreButton}
        setDisplaySeeMoreButton={setDisplaySeeMoreButton}
        forwardedRef={ref}
        isOpen={isOpen}
      />
    );
  },
);

type NotificationPickerProps = {
  onClick: () => void;
  notificationCount: number;
  isNotificationsOpen: boolean;
};

const NotificationPicker = React.forwardRef<
  HTMLDivElement,
  NotificationPickerProps
>(({ onClick, notificationCount, isNotificationsOpen }, ref) => (
  <NotificationBell
    disableArrow
    onClick={onClick}
    isOpen={isNotificationsOpen}
    forwardedRef={ref}
  >
    {notificationCount ? (
      <BellIcon
        icon="bellNotification"
        data-testid="notification-bell-with-count"
      />
    ) : (
      <BellIcon icon="bell" data-testid="notification-bell" />
    )}
  </NotificationBell>
));

NotificationPicker.displayName = 'NotificationPicker';

type NotificationsProps = {
  isOpen?: boolean;
  notificationCount?: number;
};

const Notifications = ({
  isOpen,
  notificationCount: notifCount = 0,
}: NotificationsProps) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(
    isOpen || false,
  );
  const [notificationCount, setNotificationsCount] = useState(notifCount);

  const { user } = useUser();
  const userId = user?.id || '';

  const listRef = useRef(null);
  const pickerRef = useRef(null);

  useEffect(
    () =>
      closeOnClickOutside({
        setState: setIsNotificationsOpen,
        buttonRef: pickerRef,
        contentRef: listRef,
      }),
    [listRef, pickerRef, isNotificationsOpen],
  );

  useEffect(() => {
    const channelName = `global-${userId}`;
    const channel = subscribeToChannel(channelName);

    if (!channel) return;

    bindChannel(channel, 'notifications-count', (info: number) => {
      setNotificationsCount(info);
    });

    return function cleanup() {
      unbindChannel(channel, 'notifications-count');
    };
  });

  return (
    <>
      <NotificationPicker
        notificationCount={notificationCount}
        isNotificationsOpen={isNotificationsOpen}
        onClick={() => {
          setIsNotificationsOpen(!isNotificationsOpen);
          setNotificationsCount(0);
        }}
        ref={pickerRef}
      />
      {isNotificationsOpen && (
        <NotificationList isOpen={isNotificationsOpen} ref={listRef} />
      )}
    </>
  );
};

export default Notifications;
export { NotificationPicker, NotificationList };
