import cookies, { CookieAttributes } from 'js-cookie';
import { ON_SERVER } from './context';

// eslint-disable-next-line no-shadow
enum Cookie {
  Session = 'Session',
  UserToken = 'UserToken',
  Onboarding = 'Onboarding',
  flashMessage = 'flashMessage',
  UserLobby = 'UserLobby',
  UserMatchmakingSearch = 'UserMatchmakingSearch',
  'pl-theme' = 'pl-theme',
}

const setCookie = (
  key: keyof typeof Cookie,
  value: string,
  options?: CookieAttributes,
) => {
  if (ON_SERVER) throw new Error(`Can only set cookies on the client`);
  const defaultOptions: CookieAttributes = {
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'strict',
  };
  cookies.set(key, value, { ...defaultOptions, ...(options || {}) });
};

const getCookie = (key: keyof typeof Cookie) => {
  if (ON_SERVER) throw new Error(`Can only get cookies on the client`);
  return cookies.get(key);
};

const removeCookie = (key: keyof typeof Cookie) => {
  if (ON_SERVER) throw new Error(`Can only remove cookies on the client`);
  cookies.remove(key);
};

export { getCookie, removeCookie, setCookie };
