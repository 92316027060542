import React, { useEffect, useState } from 'react';
import { AvailableIcons, Iconography, darkTheme } from 'components/styles';
import { ThemeProvider } from 'styled-components';
import { useGlobal } from 'components/util/global-context';
import { useModal } from 'components/util/modal-context';
import SelectInput from 'components/atoms/select-input';
import { ResponsivePill } from 'components/atoms/button/button';
import { NewCheckbox } from 'components/atoms/new-checkbox';
import {
  EntryFeePicker,
  EntryFeeAmount,
} from 'components/atoms/entry-fee-picker';
import ServiceFeeComponent from 'components/molecules/service-fee/service-fee';
import { useUser } from 'components/util/user-context';
import {
  ConsolesFragment,
  UserMatchmakingStatus,
  useSearchInstantMatchMutation,
} from 'graphpl/core';
import { useToast } from 'components/util/toast-context';
import { CenterSpinner } from 'components/atoms/loading-spinner';
import MlbPlatformModal, {
  isMLBPlatformError,
} from 'components/organisms/mlb-platform-modal';
import depositNowToast, {
  isLackOfFundsError,
} from 'components/molecules/deposit-now-toast';
import { useRouter } from 'next/router';
import AddGamertag, {
  AddGamertagHeader,
  gamertagIsMissing,
  // messageMap,
} from 'components/organisms/add-gamertag-modal';
import {
  HeaderText,
  HeaderWrapper,
  ModalWrapper,
  SubHeaderText,
  TitleWrapper,
  HeaderIconWrapper,
  SubHeader,
  InputWrapper,
  BottomWrapper,
  ServiceFeeText,
  CheckboxWrapper,
  CheckboxName,
  CheckboxRow,
  CheckboxTextWrapper,
  CheckboxDescription,
  CheckboxToggleWrapper,
  EmptyServiceFeeText,
} from './instant-match-modal.styles';
import {
  getDefaultGame,
  getGame,
  getMMEnabledGames,
} from '../utilities/create-match-game-helpers';
import {
  getConsole,
  getConsoles,
  getConsolesForSelectInput,
} from '../utilities/create-match-console-helpers';

const TWITCH_NOT_LINKED =
  'A twitch account must be linked to play with this game format';

const amounts: EntryFeeAmount[] = [
  { value: 5, label: '$5' },
  { value: 10, label: '$10' },
  { value: 25, label: '$25' },
  { value: 50, label: '$50' },
  { value: 100, label: '$100' },
];

const getServiceFeeComponentAmounts = (selectedAmounts: {
  minAmount?: EntryFeeAmount;
  maxAmount?: EntryFeeAmount;
}) => {
  const serviceFeeAmounts: { value: number }[] = [];
  if (selectedAmounts.minAmount) {
    serviceFeeAmounts.push(selectedAmounts.minAmount);
  }
  if (selectedAmounts.maxAmount) {
    serviceFeeAmounts.push(selectedAmounts.maxAmount);
  }
  return serviceFeeAmounts;
};

export const InstantMatchModal = ({
  storybookDisabled = false,
}: {
  storybookDisabled?: boolean;
}) => {
  const router = useRouter();
  const { games, consoles } = useGlobal();
  const { displayModal, dismissModal } = useModal();
  const { user, setUserRaw } = useUser();
  const { displayToast, dismissToast } = useToast();
  const defaultGame = getDefaultGame({
    games,
  });
  const allGamesEnabled = games.filter(
    ({ enabled, matchmakingEnabled }) => matchmakingEnabled && enabled,
  );
  const gameOptions = getMMEnabledGames({ games });
  const [selectedGame, setSelectedGame] = useState(defaultGame);

  const availableConsoles = getConsoles({
    selectedGame,
    consoles,
  });
  const [selectedConsole, setSelectedConsole] = useState<ConsolesFragment>(
    availableConsoles[0],
  );
  const consoleOptions = getConsolesForSelectInput({
    consoles: availableConsoles,
  });

  const gameFormats = (selectedGame?.gameFormats || []).filter(
    (gameFormat) => Boolean(gameFormat) && !gameFormat?.key?.includes('plc'),
  );
  const [selectedGameFormats, setSelectedGameFormats] = useState<string[]>([]);
  const [selectedRules, setSelectedRules] = useState<string[]>([]);
  const [selectedAmounts, setSelectedAmounts] = useState<{
    minAmount?: EntryFeeAmount;
    maxAmount?: EntryFeeAmount;
  }>({});

  const setAmounts = (amount: EntryFeeAmount) => {
    setSelectedAmounts((prev) => {
      if (!prev.minAmount) {
        return { minAmount: amount, maxAmount: undefined };
      }
      if (!prev.maxAmount && amount.value > prev.minAmount.value) {
        return { ...prev, maxAmount: amount };
      }
      if (!prev.maxAmount && amount.value < prev.minAmount.value) {
        return { ...prev, maxAmount: prev.minAmount, minAmount: amount };
      }
      if (!prev.maxAmount && amount.value === prev.minAmount.value) {
        return {};
      }
      if (amount.value === prev.minAmount.value) {
        return { minAmount: prev.maxAmount, maxAmount: undefined };
      }
      if (amount.value === prev.maxAmount?.value) {
        return { minAmount: prev.minAmount, maxAmount: undefined };
      }
      if (amount.value < prev.minAmount.value) {
        return { ...prev, minAmount: amount };
      }
      if (prev.maxAmount && amount.value > prev.maxAmount.value) {
        return { ...prev, maxAmount: amount };
      }
      return { minAmount: amount, maxAmount: undefined };
    });
  };

  const changeSelectedGameFormat = (gameFormatKey: string) => {
    if (selectedGameFormats.includes(gameFormatKey)) {
      setSelectedGameFormats((prev) =>
        prev.filter((key) => key !== gameFormatKey),
      );
      return;
    }
    setSelectedGameFormats((prev) => [...prev, gameFormatKey]);
  };

  const changeSelectedRules = (ruleKey: string) => {
    if (selectedRules.includes(ruleKey)) {
      setSelectedRules((prev) => prev.filter((key) => key !== ruleKey));
      return;
    }
    setSelectedRules((prev) => [...prev, ruleKey]);
  };

  useEffect(() => {
    setSelectedGameFormats([]);
    setSelectedRules([]);

    const newGameConsoles = getConsoles({
      selectedGame,
      consoles,
    });
    setSelectedConsole(newGameConsoles[0]);
  }, [selectedGame]);

  const [
    startSearch,
    { loading, error, called },
  ] = useSearchInstantMatchMutation({
    onCompleted: () => {
      dismissModal();
      setUserRaw((prev) => {
        if (!prev) return prev;
        if (prev?.status?.matchmakingStatus === UserMatchmakingStatus.SEARCHING)
          return prev;
        return {
          ...prev,
          status: {
            ...prev.status,
            matchmakingStatus: UserMatchmakingStatus.SEARCHING,
          },
        };
      });

      displayToast({
        subtext: 'Searching for your best matchup.',
        type: 'success',
        toastDisplayed: true,
      });
    },
    onError: (err) => {
      if (isMLBPlatformError(err.message)) {
        displayToast({
          toastDisplayed: true,
          type: 'error',
          disableTimeout: true,
          subtext: <MlbPlatformModal />,
        });
        return;
      }

      if (isLackOfFundsError(err.message)) {
        depositNowToast(displayToast, dismissToast, dismissModal);
        return;
      }

      if (err.message === TWITCH_NOT_LINKED) {
        dismissModal();
        router.push('/user/linked-accounts');
        return;
      }

      if (gamertagIsMissing(err.message)) {
        // messageParent({
        //   action: 'NAVIGATE',
        //   source: 'match-creation-modal',
        //   data: {
        //     url: '/user/gamertags',
        //     targetId: messageMap[err.message],
        //   },
        // });
        displayModal({
          type: 'default',
          header: <AddGamertagHeader message={err.message} />,
          body: <AddGamertag callback={dismissModal} message={err.message} />,
        });
        return;
      }

      displayToast({
        heading: 'An error occured',
        subtext: err.message,
        type: 'error',
        toastDisplayed: true,
      });
    },
  });

  const handleSubmit = () => {
    if (!selectedGame || !selectedGame.id || !selectedGame.gameSeriesId) return;
    if (!selectedConsole) return;
    if (!selectedAmounts.minAmount?.value) return;
    if (selectedGameFormats.length === 0) return;

    startSearch({
      variables: {
        search: {
          amountMin: selectedAmounts.minAmount.value,
          currency: 'PL_BALANCE',
          amountMax:
            selectedAmounts.maxAmount?.value || selectedAmounts.minAmount.value,
          gameId: selectedGame.id,
          gameSeriesId: selectedGame.gameSeriesId,
          consoleSettingId: selectedConsole?.id || 'ps5',
          // @ts-ignore
          gameFormatIds: selectedGameFormats,
          rules: selectedRules || undefined,
        },
      },
    });
  };

  const disabledInputs = storybookDisabled || loading || (called && !error);

  const disabledButton =
    !selectedGame ||
    !selectedGame.name ||
    !selectedConsole?.id ||
    selectedGameFormats.length === 0 ||
    !selectedAmounts.minAmount ||
    disabledInputs;

  return (
    <ThemeProvider theme={darkTheme}>
      <ModalWrapper>
        <div>
          <HeaderWrapper>
            <TitleWrapper>
              <HeaderText>Matchmaking</HeaderText>
              <SubHeaderText>
                We quickly find you a fair match up (most popular)
              </SubHeaderText>
            </TitleWrapper>
            <HeaderIconWrapper onClick={dismissModal}>
              <Iconography
                name={AvailableIcons.CROSS}
                color={darkTheme.new.information.text}
              />
            </HeaderIconWrapper>
          </HeaderWrapper>
          <SubHeader>Game</SubHeader>
          <InputWrapper>
            <SelectInput
              id="instant-match-game-selector"
              name="instant-match-game-selector"
              defaultValue="chooseGame"
              label="Game"
              highContrast
              isTransparent
              options={gameOptions}
              onChange={(event) => {
                const tempGame = getGame(event?.target?.value, allGamesEnabled);
                setSelectedGame(tempGame);
              }}
              value={selectedGame.name as string}
              disabled={disabledInputs}
            />
          </InputWrapper>
          {consoleOptions.length > 1 && (
            <>
              <SubHeader>Platform</SubHeader>
              <InputWrapper>
                <SelectInput
                  id="instant-match-console-selector"
                  name="instant-match-console-selector"
                  defaultValue="choosePlatform"
                  label="Your Platform"
                  isTransparent
                  highContrast
                  options={consoleOptions}
                  onChange={(event) => {
                    setSelectedConsole(
                      getConsole(event?.target?.value, consoles),
                    );
                  }}
                  value={selectedConsole?.displayName || ''}
                  disabled={disabledInputs}
                />
              </InputWrapper>
            </>
          )}
          <SubHeader>Game mode</SubHeader>
          <CheckboxWrapper>
            {gameFormats.map((gameFormat) => {
              if (!gameFormat || !gameFormat.name || !gameFormat.key)
                return null;

              return (
                <CheckboxRow
                  key={gameFormat.name}
                  onClick={() => {
                    if (disabledInputs) return;
                    changeSelectedGameFormat(gameFormat.key as string);
                  }}
                  disabled={disabledInputs}
                >
                  <CheckboxToggleWrapper>
                    <NewCheckbox
                      checked={selectedGameFormats.includes(gameFormat.key)}
                      isTransparent
                      highContrast
                      inverse
                    />
                  </CheckboxToggleWrapper>
                  <CheckboxTextWrapper>
                    <CheckboxName>{gameFormat.name}</CheckboxName>
                    <CheckboxDescription>
                      {gameFormat.description}
                    </CheckboxDescription>
                  </CheckboxTextWrapper>
                </CheckboxRow>
              );
            })}
          </CheckboxWrapper>
          {selectedGame.rules && selectedGame.rules.length >= 1 && (
            <>
              <SubHeader>Additional rules</SubHeader>
              <CheckboxWrapper>
                {selectedGame.rules.map((rule) => {
                  if (!rule || !rule.name || !rule.key) return null;

                  return (
                    <CheckboxRow
                      key={rule.name}
                      onClick={() => {
                        if (disabledInputs) return;
                        changeSelectedRules(rule.key as string);
                      }}
                      disabled={disabledInputs}
                    >
                      <CheckboxToggleWrapper>
                        <NewCheckbox
                          checked={selectedRules.includes(rule.key)}
                          isTransparent
                          highContrast
                          inverse
                        />
                      </CheckboxToggleWrapper>
                      <CheckboxTextWrapper>
                        <CheckboxName>{rule.name}</CheckboxName>
                        <CheckboxDescription>
                          {rule.description}
                        </CheckboxDescription>
                      </CheckboxTextWrapper>
                    </CheckboxRow>
                  );
                })}
              </CheckboxWrapper>
            </>
          )}
          <SubHeader>Entry amount</SubHeader>
          <EntryFeePicker
            type="information"
            amounts={amounts}
            minAmount={selectedAmounts?.minAmount}
            maxAmount={selectedAmounts?.maxAmount}
            setAmounts={setAmounts}
            disabled={disabledInputs}
          />
        </div>
        <BottomWrapper>
          <ServiceFeeText>
            {selectedAmounts?.minAmount ? (
              <ServiceFeeComponent
                selectedAmounts={getServiceFeeComponentAmounts(selectedAmounts)}
                userHasPlPlus={user?.hasPlPlus || false}
                highContrast
              />
            ) : (
              <EmptyServiceFeeText />
            )}
          </ServiceFeeText>
          <ResponsivePill
            size="large"
            purpose="infoInverted"
            disabled={disabledButton}
            onClick={handleSubmit}
          >
            {loading ? <CenterSpinner size={24} /> : 'Create match'}
          </ResponsivePill>
        </BottomWrapper>
      </ModalWrapper>
    </ThemeProvider>
  );
};
