import React from 'react';
import { Pill } from 'components/atoms';
import { useModal } from 'components/util/modal-context';
import styled from 'styled-components';
import { MIN_TABLET } from 'components/styles';
import { CreateMatchOptionsModal } from './create-match-options-modal';

export const CreateAMatchPill = styled(Pill)`
  width: 100%;
  @media (min-width: ${MIN_TABLET}) {
    width: ${({ theme }) => theme.spacing.customSpacing('215px')};
    margin-bottom: ${({ theme }) => theme.spacing.customSpacing('4px')};
    margin-top: ${({ theme }) => theme.spacing.BASE_SPACING};
  }

  @media (min-width: ${({ theme }) => theme.spacing.customSpacing('520px')}) {
    min-width: ${({ theme }) => theme.spacing.customSpacing('208px')};
  }
`;

export const NewCreateMatchButton = () => {
  const { displayModal } = useModal();
  const openCreateMatchFlow = () => {
    displayModal({
      type: 'layout',
      body: <CreateMatchOptionsModal />,
      modalDisplayed: true,
      displayBackButton: false,
    });
  };

  return (
    <CreateAMatchPill
      type="button"
      purpose="success"
      animation="strong"
      size="large"
      onClick={openCreateMatchFlow}
    >
      Find a match
    </CreateAMatchPill>
  );
};
