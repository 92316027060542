import { LobbiesFragment } from 'graphpl/core';

const guardBadLobby = (lobby?: LobbiesFragment | null): LobbiesFragment => {
  if (!lobby?.gameSeries?.id) return {};

  let gameSeriesId = lobby?.gameSeries?.id
    .replace('22', '')
    .replace('21', '')
    .replace('20', '')
    .replace('19', '')
    .replace('18', '')
    .replace('17', '')
    .replace('16', '');

  if (gameSeriesId.includes('cod')) {
    gameSeriesId = 'cod';
  }

  if (gameSeriesId.includes('fn')) {
    gameSeriesId = 'fn';
  }

  return {
    ...lobby,
    gameSeries: {
      ...lobby?.gameSeries,
      id: gameSeriesId,
    },
  };
};

export default guardBadLobby;
