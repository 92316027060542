import React, { useState } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import {
  fireButtonAnalytics,
  CLICK_BUTTON,
  GLOBAL_HEADER_TOGGLE_DOWN_TO_PLAY,
} from 'analytics';
import { useToast } from 'components/util/toast-context';
import { useUser } from 'components/util/user-context';
import { T } from 'react-polyglot-hooks';
import { useSetIsDownToPlayMutation } from 'graphpl/core';

const DownToPlayWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OpenToChallenges = ({
  storybookDisabled,
}: {
  storybookDisabled?: boolean;
}) => {
  const { displayToast } = useToast();
  const { user, setUser } = useUser();
  const [buttonDisabled, setButtonDisabled] = useState(storybookDisabled);

  const [setIsDownToPlay] = useSetIsDownToPlayMutation({
    onCompleted: (data) => {
      const downToPlay = data?.setDownToPlay?.isDownToPlay || false;
      setUser({ ...user, isDownToPlay: downToPlay });
      displayToast({
        toastDisplayed: true,
        type: downToPlay ? 'success' : 'info',
        subtext: (
          <T
            phrase={
              downToPlay
                ? 'downToPlay.onSuccessBody'
                : 'downToPlay.offSuccessBody'
            }
          />
        ),
        heading: (
          <T
            phrase={
              downToPlay
                ? 'downToPlay.onSuccessHeader'
                : 'downToPlay.offSuccessHeader'
            }
          />
        ),
      });
    },
    onError: () => {
      setButtonDisabled(false);
      displayToast({
        toastDisplayed: true,
        type: 'error',
        subtext: <T phrase="downToPlay.error" />,
      });
    },
  });
  const updateIsDownToPlay = async (value: boolean) => {
    setButtonDisabled(true);

    fireButtonAnalytics({
      event: CLICK_BUTTON,
      source: GLOBAL_HEADER_TOGGLE_DOWN_TO_PLAY,
      state: {
        value,
      },
    });

    setIsDownToPlay({
      variables: {
        enabled: value,
      },
    });
  };

  return (
    <DownToPlayWrapper>
      <T phrase="openToChallenges" />
      <Switch
        id="dtp-menu-toggle"
        onChange={updateIsDownToPlay}
        checked={user?.isDownToPlay || false}
        disabled={buttonDisabled}
        checkedIcon={false}
        offColor="#D4E2EC"
        onColor="#2f9bd8"
        uncheckedIcon={false}
        handleDiameter={24}
        height={32}
        width={57}
        data-testid="dtp-menu-toggle-checkbox"
        activeBoxShadow={undefined}
      />
    </DownToPlayWrapper>
  );
};

export default OpenToChallenges;
