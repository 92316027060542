import { pixelToRem } from './utils';

const MAX_PHONE_PX = 430;

const MAX_PHONE = pixelToRem(`${MAX_PHONE_PX}px`);
const MIN_TABLET = pixelToRem('431px');
const MAX_TABLET = pixelToRem('768px');
const MIN_DESKTOP = pixelToRem('769px');
const MAX_DESKTOP = pixelToRem('1280px');

const STORYBOOK_VIEWPORTS = [320, 428, 768, 1280];

const MOBILE_VIEWPORTS = [320, 430];
const DESKTOP_VIEWPORTS = [431, 1280];

export {
  MAX_PHONE_PX,
  MAX_PHONE,
  MIN_TABLET,
  MAX_TABLET,
  MIN_DESKTOP,
  MAX_DESKTOP,
  STORYBOOK_VIEWPORTS,
  MOBILE_VIEWPORTS,
  DESKTOP_VIEWPORTS,
};
