import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
  PropsWithChildren,
} from 'react';
// eslint-disable-next-line import/named
import { closeOnClickOutside } from 'helpers';
import Modal from 'layouts/modal';
import styled from 'styled-components';
import {
  MAX_PHONE,
  MIN_TABLET,
  MIN_DESKTOP,
  MAX_DESKTOP,
} from 'components/styles';
import { ModalType } from 'layouts/modal/modal.styles';

type ModalContextProps = {
  displayModal: (modalData: ModalData) => void;
  dismissModal: () => void;
  modal: ModalData;
  setModal: (modalData: ModalData) => void;
  dismissModalCurry: (behaviour: 'normal' | 'clickOutside') => () => void;
};

const defaultModal: ModalData = {
  modalDisplayed: false,
  type: 'default',
  header: null,
  body: null,
  displayBackButton: false,
};

export const ModalContext = createContext<ModalContextProps>({
  modal: defaultModal,
  setModal: () => {},
  dismissModal: () => {},
  dismissModalCurry: () => () => {},
  displayModal: () => {},
});

const HeaderWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};

  @media (min-width: ${MAX_PHONE}) {
    padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  }
`;

const BodyWrapper = styled.div<{ type: ModalType }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  @media (min-width: ${MIN_TABLET}) {
    max-height: ${({ theme, type }) =>
      type === 'skinny' ? '90vh' : theme.spacing.customSpacing('450px')};
  }

  @media (min-width: ${MIN_DESKTOP}) {
    max-height: ${({ theme, type }) =>
      type === 'skinny' ? '90vh' : theme.spacing.customSpacing('575px')};
  }

  @media (min-width: ${MAX_DESKTOP}) {
    max-height: ${({ theme, type }) =>
      type === 'skinny' ? '90vh' : theme.spacing.customSpacing('600px')};
  }

  @media (min-width: ${MAX_DESKTOP}) and (min-height: 900px) {
    max-height: ${({ theme, type }) =>
      type === 'skinny' ? '90vh' : theme.spacing.customSpacing('800px')};
  }
`;

type ModalData = {
  type: ModalType;
  body: React.ReactNode;
  header?: React.ReactNode;
  modalDisplayed?: boolean;
  displayBackButton?: boolean;
  closeWebViewInReactNativeOnClickOutside?: boolean;
  closeWebViewInReactNativeOnClose?: boolean;
};

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [modal, setModal] = useState(defaultModal);

  const dismissModalCurry = (behaviour: 'normal' | 'clickOutside') => () => {
    if (
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage &&
      ((modal.closeWebViewInReactNativeOnClickOutside &&
        behaviour === 'clickOutside') ||
        (modal.closeWebViewInReactNativeOnClose && behaviour === 'normal'))
    ) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage('modal triggered close-web-view');
    }
    setModal((prev) => ({ ...prev, modalDisplayed: false }));
  };

  const dismissModal = dismissModalCurry('normal');

  const displayModal = (modalData: ModalData) => {
    if (modal.modalDisplayed) {
      dismissModal();
    }

    setModal({
      ...modalData,
      modalDisplayed: true,
    });
  };

  const modalRef = useRef(null);

  useEffect(
    () =>
      closeOnClickOutside({
        setState: dismissModalCurry('clickOutside'),
        contentRef: modalRef,
        enabled: modal.modalDisplayed,
      }),
    [modalRef], // eslint-disable-line react-hooks/exhaustive-deps
  ); // ^ display modal is out the context of this and never actually destroys the modal

  return (
    <ModalContext.Provider
      value={{ modal, setModal, displayModal, dismissModal, dismissModalCurry }}
    >
      <Modal
        isOpen={modal.modalDisplayed}
        closeModalOnClickOutside={dismissModalCurry('clickOutside')}
        closeModal={dismissModal}
        ref={modalRef}
        modalType={modal?.type}
        displayBackButton={modal?.displayBackButton}
        blur
      >
        {modal?.header && <HeaderWrapper>{modal?.header}</HeaderWrapper>}
        <BodyWrapper type={modal?.type}>{modal?.body}</BodyWrapper>
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
