import React, { useState, useEffect } from 'react';
import { T } from 'react-polyglot-hooks';

import { useModal } from 'components/util/modal-context';
import { AvailableIcons, Iconography } from 'components/styles';
import { useTheme } from 'styled-components';
import { useRouter } from 'next/router';
// import { messageParent } from 'helpers/web-view';
import {
  ServiceFee,
  StrikethroughServiceFeeText,
  ServiceFeeInfoWrapper,
  PlPlusText,
  IconWrapper,
  StyledLink,
} from './service-fee.styles';

type Amount = {
  value: number;
};

type ServiceFeeComponentProps = {
  selectedAmounts: Amount[];
  userHasPlPlus?: boolean;
  highContrast?: boolean;
};

const defaultBandings = [
  { min: 5, max: 9.99, fee: 0.74 },
  { min: 10, max: 24.99, fee: 1.49 },
  { min: 25, max: 49.99, fee: 2.24 },
  { min: 50, max: Number.POSITIVE_INFINITY, fee: 2.77 },
];

type Banding = {
  min: number;
  max: number;
  fee: number;
};

const ServiceFeeComponent = ({
  selectedAmounts,
  userHasPlPlus = false,
  highContrast = false,
}: ServiceFeeComponentProps) => {
  const [serviceFee, setServiceFee] = useState<string>('0.00');
  const { displayModal } = useModal();
  const router = useRouter();
  const theme = useTheme();

  const calculateServiceFee = (
    amount: number,
    bandings: Banding[] = defaultBandings,
  ): string => {
    const matchingBanding = bandings.find(
      (band) => amount >= band.min && amount <= band.max,
    );
    const fee = matchingBanding ? matchingBanding.fee : 0;
    return fee.toFixed(2);
  };

  const openInfoModal = (): void => {
    displayModal({
      modalDisplayed: true,
      header: (
        <h2>
          <T phrase="serviceFeeInformationModal.title" />
        </h2>
      ),
      body: (
        <ServiceFeeInfoWrapper>
          <T phrase="serviceFeeInformationModal.content" />
        </ServiceFeeInfoWrapper>
      ),
      type: 'dialog',
      closeWebViewInReactNativeOnClickOutside: true,
      closeWebViewInReactNativeOnClose: true,
    });
  };

  useEffect(() => {
    let highestAmount = 0;
    let lowestAmount = 0;

    if (Array.isArray(selectedAmounts) && selectedAmounts.length) {
      lowestAmount = selectedAmounts.reduce((min, amount) => {
        let amountValue = 0;
        if (typeof amount === 'number') {
          amountValue = amount;
        } else if (
          typeof amount === 'object' &&
          amount !== null &&
          'value' in amount
        ) {
          amountValue = amount.value;
        }
        return amountValue < min ? amountValue : min;
      }, 10000000);
      // Handle case where selectedAmounts is an array
      highestAmount = selectedAmounts.reduce((max, amount) => {
        let amountValue = 0;
        if (typeof amount === 'number') {
          amountValue = amount;
        } else if (
          typeof amount === 'object' &&
          amount !== null &&
          'value' in amount
        ) {
          amountValue = amount.value;
        }
        return amountValue > max ? amountValue : max;
      }, 0);
    } else if (
      typeof selectedAmounts === 'number' ||
      typeof selectedAmounts === 'string'
    ) {
      // Handle case where selectedAmounts is a single number or a string
      highestAmount = Number(selectedAmounts);
      lowestAmount = Number(selectedAmounts);
    }

    if (highestAmount > 0) {
      const calculatedFee = calculateServiceFee(highestAmount);
      const calculatedLowestFee = calculateServiceFee(lowestAmount);
      const feeText =
        Array.isArray(selectedAmounts) &&
        selectedAmounts.length > 1 &&
        calculatedFee !== calculatedLowestFee
          ? `up to $${calculatedFee}`
          : `$${calculatedFee}`;

      setServiceFee(feeText);
    }
  }, [selectedAmounts]);

  return (
    <ServiceFee highContrast={highContrast}>
      {!userHasPlPlus ? (
        <>Service fee {serviceFee}</>
      ) : (
        <>
          Service fee{' '}
          <StrikethroughServiceFeeText>
            {serviceFee}
          </StrikethroughServiceFeeText>{' '}
          <PlPlusText>$0.00</PlPlusText> will be applied
        </>
      )}
      <IconWrapper onClick={openInfoModal}>
        <Iconography
          name={AvailableIcons.INFORMATION}
          size={16}
          color={highContrast ? theme.new.content : theme.new.subContent}
        />
      </IconWrapper>
      {!userHasPlPlus && (
        <StyledLink
          onClick={() => {
            // messageParent({
            //   action: 'NAVIGATE',
            //   source: 'ServiceFeeComponent',
            //   data: {
            //     url: `/subscribe`,
            //   },
            // });
            router.push('/subscribe');
          }}
        >
          Zero fees with PL+
        </StyledLink>
      )}
    </ServiceFee>
  );
};

export default ServiceFeeComponent;
