import { fontMediumLarge, fontSmall } from 'components/styles/typography';
import { customSpacing } from 'components/styles';
import { css, FlattenSimpleInterpolation } from 'styled-components';

export type ButtonSize = 'small' | 'medium' | 'large' | 'round';

export const buttonSizes = {
  small: css`
    padding-right: ${customSpacing(15)};
    padding-left: ${customSpacing(15)};
    ${fontSmall};
    height: ${customSpacing(24)};

    ::before {
      top: ${customSpacing(-7)};
      left: calc(100% - 24px);
    }
  `,
  medium: css`
    padding-right: ${customSpacing(15)};
    padding-left: ${customSpacing(15)};
    ${fontSmall};
    height: 40px;

    ::before {
      top: ${customSpacing(-7)};
      left: calc(100% - 27px);
    }
  `,
  large: css`
    padding-right: ${customSpacing(15)};
    padding-left: ${customSpacing(15)};
    ${fontMediumLarge};
    height: ${customSpacing(48)};

    ::before {
      top: ${customSpacing(-7)};
      left: calc(100% - 30px);
    }
  `,
  round: css`
    height: 48px;
    min-width: 48px;
    padding: 0;
  `,
};

export const getButtonSize = (
  buttonSize: ButtonSize,
): FlattenSimpleInterpolation => {
  return buttonSizes[buttonSize] || buttonSizes.medium;
};
