export type Consoles =
  | 'ps'
  | 'xbox'
  | 'pc'
  | 'controller'
  | 'kbm'
  | ''
  | 'ps4'
  | 'ps5'
  | 'xboxOne'
  | 'xboxSeriesX';

// eslint-disable-next-line no-shadow
export enum GameSeries {
  MADDEN = 'madden',
  COD = 'cod',
  CALLOFDUTY = 'callofduty',
  FN = 'fn',
  FORTNITE = 'fortnite',
  NBA = 'nba',
  NBA2K = 'nba2k',
  FIFA = 'fifa',
  MLB = 'mlb',
}

export type Merge<A, B> = {
  [K in keyof A]: K extends keyof B ? B[K] : A[K];
} &
  B extends infer O
  ? { [K in keyof O]: O[K] }
  : never;
