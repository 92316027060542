import { SelectInputOptions } from 'components/atoms/select-input/select-input';
import { ConsolesFragment, GamesFragment, LobbiesFragment } from 'graphpl/core';
import { getCookie } from 'helpers';

type GetConsolesProps = {
  selectedGame: GamesFragment;
  consoles: ConsolesFragment[];
};

const DEFAULT_CONSOLE: ConsolesFragment = {
  __typename: 'ConsoleSetting',
  id: 'ps5',
  displayName: 'PlayStation 5',
};

const sortConsoles = (
  consoles: ConsolesFragment[],
  lobbyConsole?: ConsolesFragment,
): ConsolesFragment[] => {
  const sortedConsoles = consoles.reduce<ConsolesFragment[]>((acc, val) => {
    if (lobbyConsole && lobbyConsole.id === val.id) return acc;
    if (val.id === 'ps4') return [...acc, val];
    if (val.id === 'ps5') return [val, ...acc];
    return [...acc, val];
  }, []);

  if (!lobbyConsole) return sortedConsoles;

  return [lobbyConsole, ...sortedConsoles];
};

const getLobbyConsole = (): ConsolesFragment | undefined => {
  const UserLobby = getCookie('UserLobby');
  if (!UserLobby) return;

  const selectedLobby = JSON.parse(UserLobby) as LobbiesFragment;

  return selectedLobby.consoleSetting || undefined;
};

export const getConsoles = ({
  selectedGame,
  consoles,
}: GetConsolesProps): ConsolesFragment[] =>
  sortConsoles(
    (
      selectedGame?.consoles?.map((consoleId) =>
        consoles.find((console) => console.id === consoleId),
      ) || [DEFAULT_CONSOLE]
    ).filter((console) => Boolean(console?.id)) as ConsolesFragment[],
    getLobbyConsole(),
  );

export const getConsolesForSelectInput = ({
  consoles,
}: {
  consoles: ConsolesFragment[];
}): SelectInputOptions =>
  consoles.map((console) => ({
    name: console.displayName || 'PlayStation 5',
    value: console.id || 'ps5',
  }));

export const getConsole = (
  consoleString: string,
  consoles: ConsolesFragment[],
): ConsolesFragment => {
  return consoles.find(({ id }) => id === consoleString) || DEFAULT_CONSOLE;
};
