import { GlobalColors } from 'components/styles';
import React from 'react';
import { useTheme } from 'styled-components';
import {
  EntryFeeAmount,
  isAmountInsideRangeSelected,
  isAmountRangeSelected,
  isAmountSelected,
} from './entry-fee-picker-helper';
import {
  AmountBackdrop,
  AmountBackdropWrapper,
  AmountButton,
  BackgroundAmount,
  ButtonBackground,
  EntryFeePickerWrapper,
  LocalTheme,
} from './entry-fee-picker.styles';

type EntryFeePickerProps = {
  type: 'default' | GlobalColors;
  amounts: EntryFeeAmount[];
  setAmounts: (amount: EntryFeeAmount) => void;
  minAmount?: EntryFeeAmount;
  maxAmount?: EntryFeeAmount;
  disabled?: boolean;
};

export const EntryFeePicker = ({
  type,
  amounts,
  minAmount,
  maxAmount,
  setAmounts,
  disabled = false,
}: EntryFeePickerProps) => {
  const theme = useTheme();
  const backgroundAmounts = amounts.slice(0, amounts.length - 1);

  const localTheme: LocalTheme = {
    text:
      type === 'default'
        ? theme.new.information.background
        : theme.new[type].text,
    highlight:
      type === 'default' ? theme.new.subContent : theme.new[type].highlight,
    background:
      type === 'default'
        ? theme.new.information.text
        : theme.new[type].background,
  };

  return (
    <>
      <AmountBackdropWrapper>
        <AmountBackdrop columns={amounts.length - 1}>
          {backgroundAmounts.map((amount) => {
            const isSelected = isAmountRangeSelected({
              amount,
              minAmount,
              maxAmount,
            });
            return (
              <BackgroundAmount
                key={amount.label}
                selected={isSelected}
                localTheme={localTheme}
              />
            );
          })}
        </AmountBackdrop>
      </AmountBackdropWrapper>
      <EntryFeePickerWrapper>
        {amounts.map((amount) => {
          return (
            <ButtonBackground
              isInRange={isAmountInsideRangeSelected({
                amount,
                minAmount,
                maxAmount,
              })}
              key={amount.label}
              localTheme={localTheme}
            />
          );
        })}
      </EntryFeePickerWrapper>
      <EntryFeePickerWrapper>
        {amounts.map((amount) => {
          const isSelected = isAmountSelected({ amount, minAmount, maxAmount });
          return (
            <AmountButton
              selected={isSelected}
              isInRange={isAmountInsideRangeSelected({
                amount,
                minAmount,
                maxAmount,
              })}
              key={amount.label}
              localTheme={localTheme}
              onClick={() => setAmounts(amount)}
              disabled={disabled}
            >
              {amount.label}
            </AmountButton>
          );
        })}
      </EntryFeePickerWrapper>
    </>
  );
};
