import styled from 'styled-components';
import { variant } from 'styled-system';
import { fontSmall } from 'components/styles';
import { Box } from '../box';

export const ViewingMatch = styled.span(
  {
    fontSmall,
    textAlign: 'right',
  },
  variant({
    prop: 'textColor',
    variants: {
      light: {
        color: (theme) => theme.palette.text.secondary,
      },
      dark: {
        color: (theme) => theme.palette.text.alternative,
      },
    },
  }),
);

export const NumberViewingText = styled(ViewingMatch)({
  fontWeight: 'bold',
  marginLeft: ({ theme }) => theme.spacing.customSpacing('3px'),
});

export const MatchViewingWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

export const IconAndTextWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
