import { FC, SVGProps } from 'react';

import Circle from './assets/material-ui/circle.svg';
import Balanced from './assets/material-ui/balanced.svg';
import Globe from './assets/material-ui/globe.svg';
import UpArrow from './assets/material-ui/up-arrow-with-shaft.svg';
import DownArrow from './assets/material-ui/down-arrow-with-shaft.svg';
import ArrowDropDown from './assets/material-ui/arrow_drop_down.svg';
import ArrowDropUp from './assets/material-ui/arrow_drop_up.svg';
import ExpandMore from './assets/material-ui/expand_more.svg';
import ExpandLess from './assets/material-ui/expand_less.svg';
import ArrowBackIos from './assets/material-ui/arrow_back_ios.svg';
import Check from './assets/material-ui/check.svg';
import CheckCircle from './assets/material-ui/check_circle.svg';
import CheckCircleFilled from './assets/material-ui/check_circle_filled.svg';
import Cancel from './assets/material-ui/cancel.svg';
import Close from './assets/material-ui/close.svg';
import Computer from './assets/material-ui/computer.svg';
import Delete from './assets/material-ui/delete.svg';
import NoComputer from './assets/material-ui/no-computer.svg';
import Clock from './assets/material-ui/clock.svg';
import Visibility from './assets/material-ui/visibility.svg';
import VisibilityOff from './assets/material-ui/visibility_off.svg';
import Home from './assets/material-ui/home.svg';
import Menu from './assets/material-ui/menu.svg';
import Info from './assets/material-ui/info.svg';
import AddCircle from './assets/material-ui/add_circle.svg';
import MinusCircle from './assets/material-ui/minus_circle.svg';
import Refresh from './assets/material-ui/refresh.svg';
import ArrowRightAlt from './assets/material-ui/arrow_right_alt.svg';
import RadioButtonChecked from './assets/material-ui/radio_button_checked.svg';
import MonetizationOn from './assets/material-ui/monetization_on.svg';
import Favorite from './assets/material-ui/favorite.svg';
import Lifeline from './assets/material-ui/lifeline.svg';
import Leaderboard from './assets/material-ui/leaderboard.svg';
import Description from './assets/material-ui/description.svg';
import LiveTv from './assets/material-ui/live_tv.svg';
import Lock from './assets/material-ui/lock.svg';
import Logout from './assets/material-ui/logout.svg';
import Search from './assets/material-ui/search.svg';
import Share from './assets/material-ui/share.svg';
import Mail from './assets/material-ui/mail.svg';
import Link from './assets/material-ui/link.svg';
import Fire from './assets/material-ui/fire.svg';
import Crosshairs from './assets/material-ui/crosshairs.svg';
import Notifications from './assets/material-ui/notifications.svg';
import Groups from './assets/material-ui/groups.svg';
import Tag from './assets/material-ui/tag.svg';
import AddPerson from './assets/material-ui/person_add.svg';
import Calendar from './assets/material-ui/calendar.svg';
import Ellipsis from './assets/material-ui/ellipsis.svg';
import Error from './assets/material-ui/error.svg';
import Warning from './assets/material-ui/warning.svg';
import History from './assets/material-ui/history.svg';
import ArrowRight from './assets/material-ui/chevron_left.svg';
import ArrowLeft from './assets/material-ui/chevron_right.svg';
import Edit from './assets/material-ui/edit.svg';
import Experiment from './assets/material-ui/experiment.svg';
import Person from './assets/material-ui/person.svg';
import Settings from './assets/material-ui/settings.svg';
import Copy from './assets/material-ui/copy.svg';
import ExternalLink from './assets/material-ui/external_link.svg';
import Gift from './assets/material-ui/gift.svg';
import HappyFace from './assets/material-ui/happy.svg';
import NeutralFace from './assets/material-ui/neutral.svg';
import SadFace from './assets/material-ui/sad.svg';
import VeryHappyFace from './assets/material-ui/super-happy.svg';
import VerySadFace from './assets/material-ui/very-sad.svg';
import Filter from './assets/material-ui/filter.svg';
import PlusCircleFill from './assets/material-ui/plus-circle-fill.svg';
import CircleStar from './assets/material-ui/circle-star.svg';
import Star from './assets/material-ui/star.svg';
import Phone from './assets/material-ui/phone.svg';
import NoController from './assets/material-ui/no-controller.svg';
import Refund from './assets/material-ui/refund.svg';
import VideoSettings from './assets/material-ui/video_settings.svg';
import HangoutVideoOff from './assets/material-ui/hangout_video_off.svg';

// eslint-disable-next-line no-shadow
export enum MaterialIcons {
  CIRCLE = 'Circle',
  BALANCED = 'Balanced',
  GLOBE = 'Globe',
  TRIANGLE_DOWN = 'ArrowDropDown',
  DOWN_ARROW = 'DownArrow',
  UP_ARROW = 'UpArrow',
  TRIANGLE_UP = 'ArrowDropUp',
  EXPAND = 'ExpandMore',
  COLLAPSE = 'ExpandLess',
  BACK_ARROW_CHEVRON = 'ArrowBackIos',
  TICK = 'Check',
  TICK_CIRCLE_OUTLINE = 'CheckCircle',
  TICK_CIRCLE_FILLED = 'CheckCircleFilled',
  HANGOUT_VIDEO_OFF = 'HangoutVideoOff',
  VIDEO_SETTINGS = 'VideoSettings',
  CROSS = 'Close',
  COMPUTER = 'Computer',
  DELETE = 'Delete',
  NO_COMPUTER = 'NoComputer',
  NO_CONTROLLER = 'NoController',
  CROSS_CIRCLE = 'Cancel',
  CLOCK = 'Clock',
  EYE = 'Visibility',
  EYE_CROSSED_OUT = 'VisibilityOff',
  ELLIPSIS = 'Ellipsis',
  HOME = 'Home',
  MENU = 'Menu',
  INFORMATION = 'Info',
  PLUS_CIRCLE = 'AddCircle',
  LIVE = 'RadioButtonChecked',
  MONEY = 'MonetizationOn',
  HEART = 'Favorite',
  LIFELINE = 'Lifeline',
  LEADERBOARD = 'Leaderboard',
  RULES = 'Description',
  LIVE_TV = 'LiveTv',
  LOGOUT = 'Logout',
  LOCK = 'Lock',
  SEARCH = 'Search',
  SHARE = 'Share',
  MAIL = 'Mail',
  LINK = 'Link',
  FLAME = 'Fire',
  KILLS = 'Crosshairs',
  NOTIFICATIONS = 'Notifications',
  TEAM = 'Groups',
  HASHTAG = 'Tag',
  ADD_PERSON = 'AddPerson',
  CALENDAR = 'Calendar',
  ERROR = 'Error',
  WARNING = 'Warning',
  HISTORY = 'History',
  ARROW_LEFT = 'ArrowRight',
  ARROW_RIGHT = 'ArrowLeft',
  EDIT = 'Edit',
  EXPERIMENT = 'Experiment',
  PERSON = 'Person',
  SETTINGS = 'Settings',
  COPY = 'Copy',
  EXTERNAL_LINK = 'ExternalLink',
  GIFT = 'Gift',
  FILTER = 'Filter',
  PLUS_CIRCLE_FILL = 'PlusCircleFill',
  CIRCLE_STAR = 'CircleStar',
  STAR = 'Star',
  PHONE = 'Phone',
  MINUS_CIRCLE = 'MinusCircle',
  REFRESH = 'Refresh',
  ARROW_RIGHT_ALT = 'ArrowRightAlt',
  REFUND = 'Refund',
  VERY_SAD_FACE = 'VerySadFace',
  SAD_FACE = 'SadFace',
  NEUTRAL_FACE = 'NeutralFace',
  HAPPY_FACE = 'HappyFace',
  VERY_HAPPY_FACE = 'VeryHappyFace',
}

export const standardIconComponentMap: Record<
  MaterialIcons,
  FC<SVGProps<SVGElement>>
> = {
  Circle,
  Balanced,
  Globe,
  ArrowDropDown,
  ArrowDropUp,
  UpArrow,
  DownArrow,
  ExpandMore,
  ExpandLess,
  ArrowBackIos,
  Check,
  CheckCircle,
  CheckCircleFilled,
  HangoutVideoOff,
  VideoSettings,
  Close,
  Computer,
  Delete,
  NoComputer,
  Cancel,
  Visibility,
  VisibilityOff,
  NoController,
  Home,
  Menu,
  Info,
  AddCircle,
  MinusCircle,
  Refresh,
  RadioButtonChecked,
  ArrowRightAlt,
  MonetizationOn,
  Favorite,
  Lifeline,
  Leaderboard,
  Description,
  LiveTv,
  Lock,
  Logout,
  Search,
  Share,
  Mail,
  Link,
  Fire,
  Crosshairs,
  Notifications,
  Groups,
  Tag,
  AddPerson,
  Calendar,
  Error,
  Warning,
  History,
  ArrowRight,
  ArrowLeft,
  Edit,
  Experiment,
  Person,
  Settings,
  Copy,
  ExternalLink,
  Gift,
  VerySadFace,
  SadFace,
  NeutralFace,
  HappyFace,
  VeryHappyFace,
  Filter,
  PlusCircleFill,
  CircleStar,
  Star,
  Phone,
  Ellipsis,
  Clock,
  Refund,
};
