import { FC, SVGProps } from 'react';
import Twitch from './assets/socials/twitch.svg';
import LoungeCredits from './assets/socials/lounge-credits.svg';

// eslint-disable-next-line no-shadow
export enum SocialIcons {
  TWITCH = 'Twitch',
  LOUNGE_CREDITS = 'LoungeCredits',
}

export const socialIconsComponentMap: Record<
  SocialIcons,
  FC<SVGProps<SVGElement>>
> = {
  Twitch,
  LoungeCredits,
};
