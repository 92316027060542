import React from 'react';
import styled from 'styled-components';
import { visuallyHidden } from 'components/styles';

const RadioContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  ${visuallyHidden}
`;

const Icon = styled.svg`
  fill: ${({ theme }) => theme.checkbox.selected};
  stroke: transparent;
  stroke-width: 0;
`;

const StyledRadio = styled.button<{ checked: boolean }>`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${({ theme }) => theme.checkbox.default};
  border-radius: 9px;
  transition: all 150ms;
  ${HiddenRadio}:focus + & {
    box-shadow: ${({ theme }) =>
      `0 0 0 ${theme.spacing.customSpacing('1px')} ${theme.checkbox.active}`};
  }
  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
  padding: 0px;
  outline: none;
`;

type RadioProps = {
  group: string;
  id: string;
  selected: boolean;
  onChange: (id: string) => void;
  disabled?: boolean;
};

const Radio = ({
  group = 'defaultGroup',
  id = 'null',
  selected = false,
  onChange = () => {},
  disabled,
}: RadioProps) => {
  const handleClick = () => {
    onChange?.(id);
  };

  return (
    <RadioContainer>
      <HiddenRadio
        onChange={handleClick}
        onClick={handleClick}
        checked={selected}
        name={group}
        id={id}
        data-testid={`${group}-${id}-radio`}
        disabled={disabled}
      />
      <StyledRadio
        onChange={handleClick}
        onClick={handleClick}
        checked={selected}
        disabled={disabled}
      >
        <Icon viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="6" />
        </Icon>
      </StyledRadio>
    </RadioContainer>
  );
};

export default Radio;
