import styled from 'styled-components';
import SVG from 'svgs';
import {
  smallerShadow,
  MAX_PHONE,
  MIN_TABLET,
  visuallyHidden,
} from 'components/styles';
import DropdownItem from '../item';

export const BackSVG = styled(SVG)`
  padding-right: ${({ theme }) => theme.spacing.customSpacing('12px')};
`;

export const DropdownSubMenuHeader = styled(DropdownItem)`
  @media (min-width: ${MIN_TABLET}) {
    ${visuallyHidden}
  }
`;

export const DropdownBackdrop = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.modalOverlay};
  backdrop-filter: blur(10px);
  z-index: ${({ theme }) => theme.depth.DROPDOWN};

  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: easy-in;

  @media (min-width: ${MIN_TABLET}) {
    ${visuallyHidden}
  }
  @media (prefers-reduced-motion) {
    transition: none;
  }
`;

export const DropdownListWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

  padding: ${({ theme }) => theme.spacing.HALF_BASE_SPACING} 0;

  background-color: ${({ theme }) => theme.body};
  border-radius: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  ${smallerShadow}
  z-index: ${({ theme }) => theme.depth.DROPDOWN};
  position: absolute;
  width: max-content;

  @media (max-width: ${MAX_PHONE}) {
    position: fixed;
    z-index: ${({ theme }) => theme.depth.DROPDOWN};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    left: 0;
    right: 0;
    bottom: 0;

    height: 75%;
    width: 100%;
    overflow-y: scroll;
  }
`;

export const ItemSideElement = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  right: 0;
  height: auto;
  top: ${({ theme }) => theme.spacing.customSpacing('-11px')};
  transform: translateX(100%);
  position: relative;
  align-self: flex-start;

  @media (max-width: ${MAX_PHONE}) {
    position: fixed;
    top: auto;
    right: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(0%);

    height: 75%;
    width: 100%;
    overflow-y: scroll;
    z-index: 1234567894123;
  }
`;

export const DropdownSubListWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

  padding: ${({ theme }) => theme.spacing.HALF_BASE_SPACING} 0;

  background-color: ${({ theme }) => theme.body};
  border-radius: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  ${smallerShadow}
  z-index: ${({ theme }) => theme.depth.DROPDOWN};
  position: absolute;
  width: max-content;

  @media (max-width: ${MAX_PHONE}) {
    position: fixed;
    z-index: ${({ theme }) => theme.depth.DROPDOWN};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    left: 0;
    right: 0;
    bottom: 0;

    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
`;
