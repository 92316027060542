import styled from 'styled-components';
import { fontSmall } from 'components/styles';

export const SectionDescription = styled.span<{ textColor: 'light' | 'dark' }>`
  ${fontSmall};
  color: ${({ theme, textColor }) =>
    textColor === 'light'
      ? theme.palette.text.primary
      : theme.palette.text.alternative};
`;

export const SectionTitle = styled(SectionDescription)`
  font-weight: bold;
  margin-left: 0px;
`;

export const RuleTitleWrapper = styled.span<{
  titleBackground: 'light' | 'dark';
}>`
  padding-right: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  padding-left: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  border-radius: ${({ theme }) => theme.spacing.customSpacing('2px')};
  margin-bottom: ${({ theme }) => theme.spacing.customSpacing('2px')};
  background-color: ${({ theme, titleBackground }) =>
    titleBackground === 'light'
      ? theme.palette.background.primary
      : theme.palette.background.alternative};
`;

export const RuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;
