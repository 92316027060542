// eslint-disable-next-line no-shadow
export enum Locales {
  en = 'en',
  es = 'es',
}

type PhraseSet = Record<
  string,
  | string
  | Record<
      string,
      | string
      | Record<string, string | Record<string, string | Record<string, string>>>
    >
>;

const translations: Record<Locales, PhraseSet> = {
  en: {
    aboutTimeAgo: 'About %{time} ago',
    accountSettings: 'Account Settings',
    accountNotVerrified: 'Account not verified',
    activeNow: 'Active now',
    addCard: 'Add card',
    addNewCard: 'Add credit/debit card',
    addFunds: 'Add funds',
    admin: 'Admin',
    addAccount: 'Add account',
    addOpponent:
      'Add your opponent as a friend in the %{game} lobby using their %{platform} username',
    alreadyHaveAccount: 'Already have an account?',
    allTournaments: {
      row: {
        plPlusOnlyTag: 'Free with PL+',
        featuredTag: 'Featured',
        plPlusOnly: 'PL+ Only',
      },
    },
    aboveCurrentLimit: 'Above current %{limit} limit.',
    and: 'and',
    apparelStore: 'Apparel Store',
    areYouSure: 'Are you sure?',
    backToLogin: 'Back to Login',
    back: 'Back',
    betOnYourself: 'Bet on Yourself.',
    betOnYourselfPlaying: 'Bet on yourself playing %{gameSeriesId}',
    blockUser: 'Block user',
    bestDeal: 'Best Deal',
    cancel: 'Cancel',
    cancelSearch: 'Cancel search',
    cancelledAt: 'Cancelled at',
    cardWillBeRemoved: 'The card will be removed from your account.',
    concede: 'Concede',
    continue: 'Continue',
    chatCapitalized: 'Chat',
    chat: 'chat',
    challenge: 'Challenge',
    challenges: 'Challenges',
    chooseEntryAmount: 'Entry amount',
    chooseGame: 'Choose a game',
    choosePlatform: 'Choose a platform',
    chooseFeeOrRange: 'Choose an entry amount or range',
    chooseFee: 'Choose an entry amount',
    completed: 'Completed',
    confirm: 'Confirm',
    confirmPayment: 'Confirm payment',
    createAccount: 'Create account',
    createAMatch: 'Create a match',
    createChat: 'Create chat',
    createGroupChat: 'Create group chat',
    createMatch: 'Create match',
    createNewChat: 'Create new chat',
    createTournament: 'Create tournament',
    creditCard: 'Credit Card',
    currentMatch: 'Current match',
    earnCash: 'Earn cash',
    dateOfBirth: 'Date of birth',
    day: 'Day',
    dateTimeAt: '%{date} at %{time}',
    deposit: 'Deposit',
    depositAmount: 'Deposit amount',
    depositOrSubscribe: 'Deposit or subscribe',
    depositInstead: 'Deposit instead',
    depositResponses: {
      errorHeading: 'Deposit Error',
      redirectToComplete:
        'We are redirecting you to complete your deposit, if you are not redirected <a href="%{link}">click here</a>',
      success: {
        heading: 'Deposit Success',
        message: 'You just deposited $%{amount}',
      },
      pending: {
        heading: 'Deposit Pending',
        message:
          'Your deposit is pending, once we have confirmation of payment your balance will be updated.',
      },
      refused: {
        heading: 'Deposit Refused',
      },
      error: {
        heading: 'Deposit Error',
      },
      unknown: {
        message: 'Something went wrong during payment. Please contact support',
      },
    },
    depositSummary: '$%{value}',
    depositTotal: 'Total Deposit',
    dfs: {
      lobby: {
        matches: 'Head to Head',
        open: 'Tournaments',
        OPEN_LOUNGE: 'Tournament',
        OPEN: 'Match',
      },
      picks: {
        HIGHER: 'More',
        LOWER: 'Less',
      },
      createMatchTitle: {
        create: 'Create a match',
        open: 'Open match',
        challenge: 'Create a challenges',
        join: 'Join a match',
      },
      tiebreak: {
        info_1: 'Correct projections earn points.',
        info_2: 'If tied on points, tiebreaker decides winner.',
        info_3: 'Tiebreaker is closest projection to actual result.',
      },
      tournaments: {
        info_line_1: `Highest score wins the tournament. `,
        info_line_2: `More players means bigger prizes! `,
        info_line_3: 'Submit multiple entries to improve your odds!',
      },
      match: {
        status: {
          LOCKED_IN: 'Locked in',
          PENDING: 'Pending',
          LIVE: 'Live',
          COMPLETED: 'Completed',
          CREATED: 'Created',
          CANCELLED: 'Cancelled',
        },
        winnings: {
          REFUNDED: 'Refunded',
          CREATED: 'wins',
          LOCKED_IN: 'wins',
          PENDING: 'wins',
          LIVE: 'wins',
          IN_PROGRESS: 'wins',
          AWAITING_PAYOUT: 'wins',
          CANCELLED: 'Cancelled',
          COMPLETED: "Won on Players' Lounge",
        },
        tiebreakWin: '%{username} wins by tie-breaker',
        wins: '%{username} wins',
      },
    },
    subscriptionSummary: '$%{value}/mo',
    subscriptionTotal: 'Subscription',
    directChallengeSent: 'Challenge successfully sent!',
    dontHaveAccount: "Don't have an account?",
    downToPlay: {
      downToPlay: 'Down to play',
      error: 'Unable to update down to play status',
      onSuccessHeader: 'You’re Down to Play',
      offSuccessHeader: 'No longer Down to Play',
      onSuccessBody:
        'You are now open to challenges. Other players will see you in the Down to Play section of the lobby!',
      offSuccessBody:
        'You are no longer open to challenges. You won’t show up in the Down to Play section of the lobby!',
    },
    edit: 'Edit',
    editCards: 'Edit saved cards',
    enterEmail: 'Enter the email address you used to sign up',
    emailAddress: 'Email address',
    twoFactor: 'Two factor code',
    entry: 'entry',
    entryFee: 'Entry Fee',
    errorRefresh: 'Error! Please refresh the page.',
    error: {
      whoops: 'Whoops, something went wrong! ',
      refreshAndSupport:
        'Try reloading this page. If this keeps happening, please contact support.',
    },
    expiryDate: 'Exp %{month}/%{year}',
    extend: 'Extend 15 minutes',
    faq: 'FAQ',
    findAMatch: 'Find a match',
    findAMatchDfs: "Play Pick'em",
    firstName: 'First name',
    forgotPassword: 'Forgot password',
    codeRedeem: 'Redeem a Code Reward',
    referrer: {
      heading: 'Get $5 free',
      subtext: 'Refer friends, earn cash',
    },
    rewardsStore: 'Rewards Store',
    spendLoungeCredits: 'Spend Lounge Credits',
    redeemRewards: 'Redeem Rewards',
    form: {
      deposit: {
        label: {
          customAmount: 'Custom amount',
        },
      },
      card: {
        label: {
          name: 'Name on card',
          number: 'Card number',
          expiration: 'Valid until',
          csc: 'CVC', // Card security code
          country: 'Country',
          postalcode: 'Billing ZIP code', // Postalcode as per html spec naming
        },
        validation: {
          name: {
            required: "Please enter the cardholder's name",
            pattern: 'Enter a valid card holder name',
          },
          number: {
            required: 'Card number is required',
            invalid: 'Card number you have entered is invalid',
          },
          expiration: {
            required: 'Card expiration is required',
            invalid: 'Expiration date you have entered is invalid',
          },
          csc: {
            required: 'CVC is required',
            invalid: 'CVC is invalid',
          },
          country: {
            required: 'Select a country',
          },
          postalcode: {
            required: 'Enter a ZIP code',
            invalid: 'Enter a valid ZIP code',
          },
        },
      },
      preauth: {
        label: {
          amount: 'Amount',
        },
        validation: {
          amount: {
            required: 'Enter an amount',
          },
        },
      },
    },
    free: 'Free',
    full: 'Full',
    tenDollarMatchesUnlock: '$10 matches unlocked instantly',
    ten2550DollarMatchesUnlock: '$10, $25 and $50 matches unlocked instantly',
    twentyFive50DollarMatchesUnlock: '$25 and $50 matches unlocked instantly',
    fiftyDollarMatchesUnlock: '$50 matches unlocked instantly',
    lowerThan50Unlock: 'Deposit $50 or more to play in $10 matches instantly.',
    gamertag: {
      enterYourTag: 'Enter your %{tag} ID',
      addingGamertagFailed: 'Adding gamertag failed',
      resubmit:
        'We have successfully added your gamertag, you can now continue.',
      success: 'Successfully added gamertag.',
    },
    gameStarted: 'We have started the game',
    gameType: 'Game Type',
    genericErrorMessage:
      'Looks like something went wrong, please try again. If this happens again please contact support',
    goodSportQuestion: 'Were they a good sport?',
    goToMatch: 'Go to match',
    goToLounge: 'Go to lounge',
    goToPayment: 'Go to payment',
    handicapBanner: {
      header: 'Team Shuffle LTM is active in matches up to $10',
      body:
        'Each player is assigned 3 teams that they can choose from based on their skill level relative to their opponent.',
    },
    havingAProblem: 'Having a problem?',
    DDInformationModal: {
      title: 'How Daily Dollars work',
    },
    LCInformationModal: {
      title: 'How Lounge Credits work',
    },
    plcInformationModal: {
      beta: 'BETA',
      twitchRequired: 'Twitch Required',
      title: 'Solo Bet game modes',
    },
    serviceFeeInformationModal: {
      title: 'Service Fees',
      content:
        'Service fees help cover the costs of running PL. It also fuels the enhancement of product features, increases tournament prize pools, and bolster our 24/7 customer service. PL+ members pay no service fees.',
    },
    home: 'Home',
    howItWorks: 'How it works',
    hundredMillionWon: '$140,000,000+ won since 2018',
    idVerification: 'ID Verification',
    inDispute: 'In dispute',
    inputType: 'Input',
    instantMatch: 'Instant match',
    joinNow: 'Join now',
    joinPL: "Join Players' Lounge",
    joined: 'Joined',
    joinFor: 'Join for $%{value}',
    joinForFree: 'Free',
    entryAmount: '$%{value} entry',
    checkIn: 'Check In',
    linkedAccounts: 'Linked Accounts',
    linkingTwitchAccount: 'Linking your Twitch account...',
    live: 'Live',
    liveStreams: 'Live streams',
    lowerLanding1: "We'll match you with an opponent of similar skill",
    lowerLanding2: 'You play each other online on your favorite console',
    lowerLanding3: 'Win and receive cash instantly',
    lastName: 'Last name',
    locked: 'Locked',
    login: 'Log in',
    submitTwoFactor: 'Confirm',
    loginTwitch: 'Log in with Twitch',
    loginToPl: "Log in to Players' Lounge",
    logout: 'Log Out',
    lounge: 'Lounge',
    matches: 'Matches',
    matchmaking: 'Matchmaking',
    matchCompleted: 'Match Complete',
    matchDetails: 'Match Details',
    matchLength: 'Match Length',
    matchOptions: 'Match Options',
    matchSettings: 'Match Settings',
    matchSettingsDfs: "PL Pick'em",
    matchPlay: 'Play the match. Submit the scores when you have finished',
    mostPlayedEmpty: 'People you play with frequently will show up here',
    mostPopular: 'Popular',
    networkError: 'Network error',
    next: 'Next',
    noStreamsFound: 'There are no streams available',
    noStreamFound: 'No stream found',
    noGroupChatSettings:
      'Group chat settings are coming soon, Please visit the app to control group settings.',
    onboarding: {
      getStarted: 'Get started',
      memberPerk: 'Member perk',
    },
    or: 'or',
    openChallenge: 'Open challenge',
    page: {
      title: {
        allTournaments: "%{gameSeries} Tournaments - Players' Lounge",
        checkout: "Checkout - Players' Lounge",
        codeRedeemed: "Redeemed code - Players' Lounge",
        default: "Players' Lounge. Bet on yourself.",
        deposit: "Deposit - Players' Lounge",
        faq: "FAQ - Players' Lounge",
        linkedAccounts: "Linked accounts - Players' Lounge",
        login: "Login - Players' Lounge",
        lounge: "Lounge - Players' Lounge",
        dfsLounge: "Pick 'em - Players' Lounge",
        dfsLoungeDetailed: "NFL | Pick 'em - Players' Lounge",
        loungeDetailed: "%{game} | %{console} Lounge - Players' Lounge",
        messages: "Messages - Players' Lounge",
        privacyPolicy: "Privacy Policy - Players' Lounge",
        signup: "Signup - Players' Lounge",
        subscription: {
          buy: "Subscribe - Players' Lounge",
        },
        kotl: "King of the Lounge - Players' Lounge",
        restrictedLocations: "Restricted Locations - Players' Lounge",
        termsOfService: "Terms of Service - Players' Lounge",
        subscriptionManagement: "Manage your Subscription - Players' Lounge",
        twitchStream: "%{username}'s Stream - Players' Lounge",
        twitchAllStreams: "All Stream's - Players' Lounge",
        profile: "%{username}'s Profile - Players' Lounge",
        welcomeToPLPlus: "Welcome to PL+ - Players' Lounge",
      },
    },
    payment: 'Payment',
    paymentOptionsLoading: 'Getting the best payment methods available for you',
    paymentOptionsProblem:
      'There was a problem getting payment methods, please refresh the page. If the problem persists please contact support',
    playFreeWinBig: 'Play free, win big',
    preauthInfo:
      'In order to verify your card, we have charged your card with <b>2 amounts</b> that will be refunded shortly.',
    preauthAction:
      '<b>Please check your statement</b> and enter the amounts to verify your card',
    preauthVerifyCard: 'Verify card',
    profile: 'Profile',
    profilePhrases: {
      joined: 'Joined',
      matches: 'Matches',
      followers: 'Followers',
      challenge: 'Challenge',
      errorMsg: 'There was a problem getting this user',
      rating: 'Rating',
    },
    timeMins: '%{mins} minutes',
    matchHistory: {
      error: 'There has been a problem loading the match history.',
      missing: 'No match history found for this lobby.',
      live: 'Live',
      won: 'Won',
      lost: 'Lost',
      playingNow: 'Playing now',
    },
    tournamentHistory: {
      error: 'There has been a problem loading the tournament history.',
      missing: 'No tournament history found for this lobby.',
    },
    maxWagerExceededTitle: 'Maximum wager exceeded',
    maxWagerExceeded:
      '%{wager} exceeds your maximum wager of %{maxWager}. Contact support if you believe this should be altered.',
    membershipActive: 'Membership Active',
    month: 'Month',
    mostPlayed: 'Most Played',
    myMatches: 'My Matches',
    noReceivedChallenges:
      'You haven’t received any challenges yet. Use the button below to send a challenge, or try searching for an instant match!',
    noSentChallenges:
      'You haven’t sent any challenges yet. Find an opponent to challenge in the lobby, or search for an instant match!',
    noTournaments: 'There are no upcoming tournaments',
    openMatch: 'Open match',
    yourOpenMatch: 'Your open match',
    openToChallenges: 'Open to challenges',
    playAgainQuestion: 'Would you play them again?',
    playFor: 'Play for ',
    playMatch: 'Play match',
    playMatchWith: 'Play on %{console}',
    playNow: 'Play now',
    playPopularVideoGames: 'Play popular video games for real money',
    graphqlError: 'Error, please try again!',
    graphqlLoading: 'Loading...',
    opponent: 'Opponent',
    privacyPolicy: 'Privacy Policy',
    password: 'Password',
    plr: 'PLR',
    plrRange: 'plr range',
    plrOnly: 'plr only',
    codeRedeemed: 'You have successfully redeemed the Reward Code',
    couldntRedeemCode: `We couldn't redeem the Reward Code`,
    promoCode: 'Promo code',
    readRules: "I've read the rules",
    realMoney: 'real money',
    received: 'Received',
    recentMatch: 'Recent match',
    referFriends: 'Refer Friends',
    rematchFor: 'Rematch for %{amount}',
    remove: 'Remove',
    reportUser: 'Report user',
    requestCancellation: 'Request Cancellation',
    required: 'Required',
    resetPassword: 'Reset password',
    resetPasswordInvalidEmail:
      "Sorry, that doesn't look like a legit email address. Please fix any issues and try again",
    reviewRules: 'Review the rules of the match below before you begin',
    rules: 'Rules',
    sent: 'Sent',
    sendAChallenge: 'Send a challenge',
    searching: 'Searching',
    securePersonalInfoNotice:
      'Your information will not be shared with anyone.',
    seeMore: 'See more',
    selectGameAndPlatform:
      'You must select a Game and a Platform in order to send a Direct Challenge.',
    selectEntryAmount: 'Select an entry amount',
    selectOpponentAndEntryAmount: 'Select an opponent and entry amount',
    shuffleLtm: 'Shuffle LTM',
    signup: 'Sign up',
    signupAgreeTerms: 'By creating an account you agree to our',
    skillLevelQuestion: 'Was their skill level equal to yours?',
    skrillInfo:
      "Once you have completed you're skrill payment please click <a href='%{link}'>here</a>. Your balance will be updated once we have confirmed your deposit.",
    sslSecured: 'SSL secured',
    startsIn: 'Starts in %{time}',
    step: 'Step %{step}',
    streamHeaderNoTitlte: `Streaming %{gameSeriesName}, check it out`,
    streamProblem: 'Please contact our support team or try again later',
    streamNotConnected: 'This user is not currently streaming',
    submitScore: 'Submit Score',
    subscribeToPLPlus: 'Subscribe to PL+',
    subscriptions: 'Subscriptions',
    subscriptionManagement: 'Subscription Management',
    activeSubscriptions: 'Active subscriptions',
    subdetails: 'Learn more about the 30-day pass',
    summary: 'Summary',
    support: 'Support',
    termsOfService: 'Terms of Service',
    thisFeatureComingSoon: 'This feature is coming soon!',
    timeAgo: '%{time} minutes ago',
    today: 'today',
    todayAt: 'Today at %{time}',
    tomorrowAt: 'Tomorrow at %{time}',
    toPlayForThisAmount: 'To play a match for this amount',
    total: 'Total Deposit',
    totalPrizes: 'Total Prizes',
    inPrizes: 'in prizes',
    totalValue: 'Total value',
    tournaments: 'Tournaments',
    tournamentCard: {
      plPlusBadgeText: {
        isFreeForSubscribers: 'Play Free',
        isSubscribersOnly: 'Free with PL+',
      },
    },
    confirmMatchAmount: 'Confirm %{amount} match',
    useLoungeCredits: 'Use %{loungeCredits} credits',
    tournamentEntrantsTotal: '%{used} of %{total}',
    tournamentEntrants: '%{used} joined',
    tournamentMinToMaxPLR: 'PLR %{min}-%{max}',
    tournamentMaxPLR: 'Upto PLR %{max}',
    tournamentMinPLR: 'Above PLR %{min}',
    tournamentNonTimedStart: 'Starts when full',
    transactionHistory: 'Transaction History',
    unblockUser: 'Unblock user',
    unverified: 'Unverified',
    usePromoCode: 'Use promo code',
    username: 'Username',
    userWinner: 'Congrats on winning this match!',
    emailVerification: {
      pleaseVerify: 'Please verify your email',
      weSentVerification: 'We sent a verification email to ',
      checkInbox: ', check your inbox to confirm your account creation.',
      sendAgain: 'Send again',
      verificationSent: 'Email verification has been sent successfully!',
    },
    streamCard: {
      lastMatch: 'Last Match:',
      versus: 'vs',
      match: 'Match',
      viewMatch: 'View Match',
    },
    dailyDollarPhrases: {
      freeDailyDollar130Days: 'Free daily $1 for 30 days',
      dailyDollarBuy: 'Buy pass',
      dailyDollarHeader: 'Get $1 every day for 30 days',
      dailyDollarSubtitle: "That's $30 for only $9.99",
      dailyDollarBodyOne:
        'Grab the Daily Dollar and earn a dollar every day you play!',
      dailyDollarBodyTwo:
        "Play a match and we'll put $1 in your account as a reward the next day. Plus, get $1 as soon as you buy the pass.",
      dailyDollarBodyThree:
        "Use your dollar to play in matches or tournaments, or treat it like a bonus and withdraw it along with your winnings. It's up to you.",
      dailyDollarBodyFour:
        "If you're not active one day, no big deal. The pass lasts 30 days, so there's always more money to earn!",
    },
    plPlus: {
      manage: 'Manage',
      inactive: 'Inactive',
      title: 'PL Plus',
      manageInApp:
        'To manage this subscription, please head to the account settings area in the Players’ Lounge iOS app',
      reactivate: 'Reactivate',
      currentPlan: 'Current Plan:',
      switchSub: 'Switch to $24.99 / 3-months',
      subscriptionManagement: 'Subscription Management',
      subscriptionActive: 'Active subscriptions',
      noActiveSubs: {
        text: "You don't have any active subscriptions. Learn more about ",
        linkText: 'Players’ Lounge Plus.',
      },
    },
    twitchHeader: {
      follow: 'Follow',
      following: 'Following',
    },
    changeAvatarModal: {
      changeInfo: 'Change your info',
      askToChange:
        "Would you like to change your current Players' Lounge avatar to your Twitch avatar?",
      change: 'Change',
      noThanks: 'No, thanks',
    },
    verifiedCard: 'Card verified!',
    verification: {
      verifyIdentity:
        "Please verify your ID before you can continue. <br/> This won't take long.",
      verificationSuccess: {
        title: 'Verification Success',
        text: 'Congratulations! You have successfully verified your ID.',
      },
      verificationReview: {
        title: 'Verification in Review',
        text:
          'We have received your ID and need to verify it manually. Please contact support.',
      },
      verificationFailure: {
        title: 'Verification Failed',
        text:
          'Unfortunately we have been unable to verify your ID, please try again. If this continues to happen please contact support.',
      },
      verificationError: {
        title: 'Verification Error',
        text: 'Something went wrong when verifying your ID. Please try again.',
      },
    },
    linkTwitch: {
      errorHeading: 'Error linking Twitch',
      errorBody: 'No access token has been provided.',
      successHeading: 'Linked',
      successBodyWithAvatar:
        'You’ve successfully linked your Twitch account and updated your avatar',
      successBodyWithoutAvatar:
        'You’ve successfully linked your Twitch account',
      linkYourTwitch: 'Link your Twitch',
    },
    lobby: {
      shooters: 'Looking for shooters?',
      dfs: "Pick'em (Daily Fantasy)",
    },
    unlinkAccount: 'Unlink your %{provider} account',
    unlinkTwitchModal: {
      cancel: 'Cancel',
      confirm: 'Remove',
      body: 'Are you sure you want to unlink your Twitch account: %{value}',
      header: 'Are you sure?',
      toastSuccess: 'Successfully unlinked Twitch account',
    },
    relativeSkillLevels: {
      muchEasier: {
        text: 'Much easier',
        tooltip: 'Much easier',
        description: 'The player’s skill is much lower than yours',
      },
      easier: {
        text: 'Easier',
        tooltip: 'Easier',
        description: 'The player’s skill is slightly lower than yours',
      },
      balanced: {
        text: 'Balanced',
        tooltip: 'Balanced',
        description: 'The player’s skill is similar to yours',
      },
      challenging: {
        text: 'Challenging',
        tooltip: 'Challenging',
        description: 'The player’s skill is slightly higher than yours',
      },
      veryChallenging: {
        text: 'Very challenging',
        tooltip: 'Very challenging',
        description: 'The player’s skill is much higher than yours',
      },
    },
    relativeSkillModal: {
      done: 'Done',
      header: 'Relative Skill',
      skillLabels: 'Skill Labels',
      body:
        "Players' Lounge Sports automatically assigns skill labels to players based on their skill relative to the you, enhancing matchmaking and fostering a competitive gaming environment.",
      shortSummary:
        'We will always aim to show you the most balanced match possible.',
    },
    viewAll: 'View all',
    viewAllStreams: 'View all streams',
    viewFullRules: 'View full rules',
    welcome: 'Welcome',
    withdraw: 'Withdraw',
    year: 'Year',
    yesterdayAt: 'Yesterday at %{time}',
    youDeposit: 'You deposit',
    youOnlyDeposit: 'You only deposit',
    you: 'You',
    youPay: 'You pay',
    yourHost:
      'You are the host. Invite your opponent to a %{gametype} match in %{game}',
    noUsersFound: 'No users found',
    now: 'Now',
    thereWasAProblem: 'There was a problem',
    dailyDollar: 'Daily Dollar',
    subscriptionPage: {
      selectPlan: 'Select a plan',
      moreOptions: 'More billing options',
      perMonthAnnual: 'per month, billed annually',
      annualFee: '$8.33 per month | Save 40%',
      annualFeeTest: '$99.99 annually | Save 40% based on $19.99 per month',
      bestValue: 'Best Value',
      devOneDay: '$0.00',
      devTestDescription: 'one day subscription for development',
      oneMonth: '$19.99',
      perMonth: 'per month',
      threeMonths: '$29.99',
      per3Months: 'per 3 month',
      sixMonths: '$39.99',
      per6Months: 'per 6 month',
      twelveMonth: '$99.99',
      per12Months: 'per year',
      valueDescription: '3 months at $6.77/mo | Save 16%',
      finePrint: 'You are enrolling into automatic payments.',
      finePrintLine2: 'Payments continue until you cancel your subscription.',
      sidebarHeader: 'What you get',
      headerText: `Play Free, Win Big`,
      done: 'Done',
      subHeaderText: '7 day free trial, cancel any time',
      theBestWayToExperiencePlayersLounge:
        "The best way to experience Players' Lounge Sports",
      rewardOne: {
        header: 'Access to $100k in monthly tournament prizes',
        body: 'Access to over $100k in monthly prizes',
      },
      rewardTwo: {
        header: '$50 worth of Lounge Credits',
        body: 'Free ready to use instantly',
      },
      rewardThree: {
        header: 'Play-to-Earn with Twitch',
        body: 'Earn money streaming wins',
      },
    },
    soloBet: 'Solo bet',
    vsPL: "vs Players' Lounge",
    playConnect: 'Play vs House',
    max25D: '$25 max',
    threeWaysToEarn: 'Three ways to earn',
    winMoneyPlayingGamesYouLove: 'Win money playing games you love',
    headToHead: 'Head-to-Head',
    streaming: 'Streaming',
    tournamentLandingDescription:
      'Over $100k in monthly prizes, free for PL+ subscribers',
    headToHeadLandingDescription:
      'Compete against similar skilled players for real money',
    streamingLandingDescription:
      'Win money each time you win a match on your stream',
    creatingOpenChallengeAsCaptain: 'Create an open challenge',
    canApproveTeamThatJoinsMatchBeforeStart:
      'You must approve the opponent that joins to start it',
    openChallengeCreated: 'Open challenge created',
    viewDetails: 'View details',
    enterMinimumFee: 'Enter a minimum of $100',
  },
  es: {
    matchDetails: 'Detalles del partido',
    entryFee: 'Cuota de entrada',
    matchLength: 'Longitud del partido',
    gameType: 'Tipo de juego',
    inputType: 'controlador',
    edit: 'Editar',
    findAMatch: 'Encontrar una partida',
    createAMatch: 'Crear una partida',
    locked: 'bloqueado',
    page: {
      title: {
        checkout: "Pago - Players' Lounge",
        default: "Players' Lounge. Bet on yourself.",
        deposit: "Depósito - Players' Lounge",
        faq: "FAQ - Players' Lounge",
        login: "Acceso - Players' Lounge",
        lounge: "Salón - Players' Lounge",
        loungeDetailed: "%{game} | %{console} Salón - Players' Lounge",
        messages: "mensajes - Players' Lounge",
        signup: "Inscribirse - Players' Lounge",
      },
    },
  },
};

type GetTranslationsFunc = (locale?: Locales) => PhraseSet;

export const getTranslations: GetTranslationsFunc = (locale = Locales.en) => {
  const phrases = translations[locale];
  if (!phrases) return translations.en;
  return phrases;
};

export const getFallback = (phrase: string) =>
  translations.en[phrase] || 'Missing translation';
