import React from 'react';
import SVG, { SVGImages } from 'svgs';
import styled from 'styled-components';
import { fontSmaller, MIN_TABLET, visuallyHidden } from 'components/styles';
import Link from 'components/atoms/link';

const NavElementText = styled.span`
  ${fontSmaller};
  color: ${({ theme }) => theme.palette.text.alternative};

  @media (min-width: ${MIN_TABLET}) {
    ${visuallyHidden};
  }
`;

const UnreadDot = styled.div`
  height: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  width: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.error.background};
  position: absolute;
  right: 0;
`;

const NavElementWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:hover *,
  &:focus * {
    fill: ${({ theme }) => theme.palette.misc.icon.light};
  }
`;

const NavElementIcon = styled(SVG)<{ selected: boolean }>`
  fill: ${({ selected, theme }) =>
    selected ? theme.palette.misc.icon.light : theme.palette.misc.icon.blue};
`;

const IconWrapper = styled.div`
  position: relative;
`;

type GlobalNavElementProps = {
  icon: SVGImages;
  text: string;
  selected: boolean;
  href: string;
  displayRedDot?: boolean;
};

const GlobalNavElement = ({
  icon,
  text,
  selected,
  href,
  displayRedDot = false,
}: GlobalNavElementProps) => (
  <NavElementWrapper data-testid="global-nav-element" href={href}>
    <IconWrapper>
      {displayRedDot && <UnreadDot data-testid="unread-messages-dot" />}
      <NavElementIcon icon={icon} selected={selected} />
    </IconWrapper>
    {selected && <NavElementText>{text}</NavElementText>}
  </NavElementWrapper>
);

export default GlobalNavElement;
