import Pusher, { Channel } from 'pusher-js';

let pusher: Pusher | null = null;

export const initPusherSocket = (): Pusher | false => {
  if (pusher) {
    return pusher;
  }
  // Don't bother setting up pusher in storybook
  if (process.env.STORYBOOK === 'true') return false;

  pusher = new Pusher(process.env.PUSHER_APP_KEY || '', {
    cluster: process.env.PUSHER_APP_CLUSTER || '',
  });
  Pusher.logToConsole = false;
  return pusher;
};

export const clearPusherSocket = () => {
  if (pusher) {
    pusher = null;
  }
};

// /**
//  * Convenience function to subscribe to channels. Note that if
//  * we're already subscribed to the channel on this socket, we can
//  * just return that object.
//  */
export const subscribeToChannel = (channelName: string): Channel | null => {
  if (!pusher || !pusher.channel) {
    return null;
  }
  const channel = pusher.channel(channelName);
  if (channel) {
    return channel;
  }
  return pusher.subscribe(channelName);
};

/**
 * Binding to event `eventName` on the provided channel.
 * `callback` is called when an event arrives (and not when
 * this binding succeeds).
 */
export const bindChannel = <SocketData>(
  channel: Channel,
  eventName: string,
  callback: (data: SocketData) => void,
): Channel | null => {
  if (!channel) {
    return null;
  }
  return channel.bind(eventName, callback);
};

// /**
//  * Unbinding to event `eventName` on the provided channel.
//  * Note that the assumption is that we want to remove all
//  * listener functions and so this is what we do here.
//  */
export const unbindChannel = (
  channel: Channel,
  eventName: string,
): Channel | null => {
  if (!channel) {
    return null;
  }
  return channel.unbind(eventName);
};
