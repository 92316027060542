import styled from 'styled-components';
import { DropdownList, DropdownPicker, Pill, Username } from 'components/atoms';
import { fontSmall, MIN_TABLET } from 'components/styles';

export const TagWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const AddFundsPill = styled(Pill)`
  width: 100%;

  @media (min-width: ${MIN_TABLET}) {
    width: ${({ theme }) => theme.spacing.customSpacing('237px')};
  }
  margin: ${({ theme }) => theme.spacing.HALF_BASE_SPACING} 0 0 0;
`;

export const StyledUsername = styled(Username)`
  ${fontSmall}
  color: ${({ theme }) => theme.palette.text.alternative};
  margin-bottom: ${({ theme }) => theme.spacing.customSpacing('2px')};
  margin-right: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
`;

export const StyledPicker = styled(DropdownPicker)<{ isMenuOpen: boolean }>`
  color: ${({ theme, isMenuOpen }) =>
    isMenuOpen
      ? theme.palette.text.alternative
      : theme.palette.text.alternative};
  fill: ${({ theme, isMenuOpen }) =>
    isMenuOpen
      ? theme.palette.text.alternative
      : theme.palette.text.alternative};
  transition: 0.25s;

  :hover,
  :active,
  :focus {
    transition: 0.25s;
    color: ${({ theme }) => theme.palette.text.alternative};
    fill: ${({ theme }) => theme.palette.text.alternative};
  }
`;

export const StyledList = styled(DropdownList)`
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (min-width: ${MIN_TABLET}) {
    width: ${({ theme }) => theme.spacing.customSpacing('269px')};
    top: ${({ theme }) => theme.spacing.customSpacing('40px')};
    right: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  }
`;

export const LobbyText = styled.span`
  ${fontSmall}
  color: ${({ theme }) => theme.palette.text.header};
  margin-left: ${({ theme }) => theme.spacing.customSpacing('-8px')};
  max-width: 55%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: ${({ theme }) => theme.spacing.customSpacing('2px')};

  @media (min-width: ${MIN_TABLET}) {
    max-width: ${({ theme }) => theme.spacing.customSpacing('148px')};
  }
`;
