import { fontSmall, Theme } from 'components/styles';
import { css, FlattenInterpolation, ThemeProps } from 'styled-components';

export type TagVariant =
  | 'admin'
  | 'dark'
  | 'earnCash'
  | 'featuredPrize'
  | 'featuredTournament'
  | 'full'
  | 'fullFeatured'
  | 'gameLabel'
  | 'joined'
  | 'light'
  | 'plPlusLabel'
  | 'plPlusOnly'
  | 'popular'
  | 'tournamentJoined'
  | 'unverified'
  | 'winner'
  | 'live'
  | 'won'
  | 'lost';

const tagVariants = {
  winner: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.misc.winner.text};
    background-color: ${({ theme }) => theme.palette.misc.winner.background};
  `,
  admin: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.misc.admin.text};
    background-color: ${({ theme }) => theme.palette.misc.admin.background};
  `,
  light: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.text.header};
    background-color: ${({ theme }) => theme.palette.background.secondary};
    border: 1px solid ${({ theme }) => theme.palette.border.light};
    display: inline-block;
  `,
  dark: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.text.alternative};
    background-color: ${({ theme }) => theme.instantMatch.find.primary};
    display: inline-block;
  `,
  earnCash: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.misc.earnCash.text};
    background-color: ${({ theme }) => theme.palette.misc.earnCash.background};
    display: inline-block;
  `,
  full: ({
    customTextColor,
  }: {
    customTextColor?: string;
  }): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${customTextColor};
    background-color: ${({ theme }) => theme.palette.misc.full.background};
  `,
  gameLabel: ({
    tagColor,
  }: {
    tagColor?: string;
  }): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.text.alternative};
    background-color: ${({ theme }) =>
      tagColor || theme.palette.background.alternative};
  `,
  fullFeatured: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.misc.fullFeatured.text};
    background-color: ${({ theme }) =>
      theme.palette.misc.fullFeatured.background};
    ${fontSmall};
  `,
  featuredPrize: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.headings.secondary};
    background-color: ${({ theme }) => theme.palette.background.alternative};
  `,
  joined: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.misc.full.text};
    background-color: ${({ theme }) => theme.palette.misc.joined.background};
  `,
  tournamentJoined: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.misc.tournamentJoined.text};
    background-color: ${({ theme }) =>
      theme.palette.misc.tournamentJoined.background};
    ${fontSmall}
  `,
  popular: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.misc.popular.alternativeText};
    background-color: ${({ theme }) =>
      theme.palette.misc.popular.alternativeBackground};
  `,
  unverified: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.misc.unverified.text};
    background-color: ${({ theme }) =>
      theme.palette.misc.unverified.background};
  `,
  plPlusOnly: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.background.tag};
    background-color: ${({ theme }) => theme.palette.background.secondary};
    border: 1px solid ${({ theme }) => theme.palette.border.light};
  `,
  featuredTournament: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.background.tag};
    background-color: ${({ theme }) => theme.palette.background.secondary};
    border: 1px solid ${({ theme }) => theme.palette.border.featuredTournament};
  `,
  live: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.text.alternative};
    background-color: ${({ theme }) => theme.palette.success.background};
  `,
  won: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.text.alternative};
    background-color: ${({ theme }) =>
      theme.palette.headings.featuredTournament};
  `,
  lost: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.text.secondary};
    background-color: ${({ theme }) => theme.palette.background.primary};
  `,
  plPlusLabel: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    background: linear-gradient(
      45.18deg,
      #72042c 0%,
      #ff6f42 46.88%,
      #ffd541 100%
    );
    color: ${({ theme }) => theme.onboarding.memberPerkBadgeText};
  `,
  twitch: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.palette.text.alternative};
    background-color: ${({ theme }) => theme.palette.background.twitch};
  `,
};

export const getTagVariant = (
  tag: TagVariant,
  customTextColor?: string,
  tagColor?: string,
): FlattenInterpolation<ThemeProps<Theme>> => {
  if (!tagVariants[tag]) return tagVariants.light();
  return tagVariants[tag]({ customTextColor, tagColor });
};
