import {
  css,
  FlattenInterpolation,
  ThemeProps,
  DefaultTheme,
} from 'styled-components';

export type ButtonVariant =
  | 'info'
  | 'infoInverted'
  | 'success'
  | 'warning'
  | 'error'
  | 'open'
  | 'successInverted';

export const buttonVariants = {
  info: css`
    color: ${({ theme }) => theme.new.information.text};
    fill: ${({ theme }) => theme.new.information.text};
    background-color: ${({ theme }) => theme.new.information.background};
  `,
  infoInverted: css`
    color: ${({ theme }) => theme.new.information.background};
    fill: ${({ theme }) => theme.new.information.background};
    background-color: ${({ theme }) => theme.new.information.text};
  `,
  success: css`
    color: ${({ theme }) => theme.new.success.text};
    fill: ${({ theme }) => theme.new.success.text};
    background-color: ${({ theme }) => theme.new.success.background};
  `,
  successInverted: css`
    color: ${({ theme }) => theme.new.success.background};
    fill: ${({ theme }) => theme.new.success.background};
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.new.success.text};
  `,
  warning: css`
    color: ${({ theme }) => theme.new.warning.text};
    fill: ${({ theme }) => theme.new.warning.text};
    background-color: ${({ theme }) => theme.new.warning.background};
  `,
  error: css`
    color: ${({ theme }) => theme.new.error.text};
    fill: ${({ theme }) => theme.new.error.text};
    background-color: ${({ theme }) => theme.new.error.background};
  `,
  open: css`
    color: ${({ theme }) => theme.palette.openBtn.text};
    fill: ${({ theme }) => theme.palette.openBtn.text};
    background-color: ${({ theme }) => theme.palette.openBtn.background};
  `,
};

export const getButtonVariant = (
  purpose?: ButtonVariant,
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  if (!purpose || !buttonVariants[purpose]) return buttonVariants.info;
  return buttonVariants[purpose];
};
