import styled from 'styled-components';
import SVG from 'svgs';

import {
  MAX_PHONE,
  MIN_TABLET,
  MIN_DESKTOP,
  fontMediumSmall,
  visuallyHidden,
  fontSmall,
} from 'components/styles';
import Link from 'components/atoms/link';
import Paragraph from 'components/atoms/text/paragraph';

export const FooterContainer = styled.div<{ authenticated: boolean }>`
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.new.surface};
  @media (max-width: ${MAX_PHONE}) {
    display: ${({ authenticated }) => (authenticated ? 'none' : 'block')};
  }
`;

export const FooterContentContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.HALF_BASE_SPACING} 0;
  margin: 0 ${({ theme }) => theme.spacing.BASE_SPACING};
  display: grid;
  justify-content: flex-start;
  grid-column-gap: ${({ theme }) => theme.spacing.customSpacing('128px')};
  grid-template-columns: max-content max-content;
  grid-row-gap: ${({ theme }) => theme.spacing.BASE_SPACING};

  @media (min-width: ${MIN_DESKTOP}) {
    grid-column-gap: ${({ theme }) => theme.spacing.customSpacing('160px')};
    grid-template-columns: max-content max-content max-content;
  }
`;

export const UpperFooterContainer = styled.div`
  padding: 0px ${({ theme }) => theme.spacing.BASE_SPACING};
  display: grid;
  grid-template-columns: 50% 50%;
  border-top: 1px solid ${({ theme }) => theme.new.divisions};
`;

export const SSL = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
`;

export const SSLText = styled.span`
  ${fontMediumSmall};
  font-weight: 700;
  font-family: 'Plus Jakarta Sans';
  color: ${({ theme }) => theme.new.subContent};
  margin-left: ${({ theme }) => theme.spacing.customSpacing('12px')};
  text-align: left;

  @media (min-width: ${MIN_TABLET}) {
    margin-left: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
  }
`;

export const LowerFooterContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};
  justify-content: space-between;
  display: block;
  @media screen and (min-width: 40em) {
    display: flex;
  }
`;

export const MobileOnlyFooterContainer = styled.div`
  ${visuallyHidden};

  @media (max-width: ${MAX_PHONE}) {
    display: flex;
    padding: ${({ theme }) => theme.spacing.BASE_SPACING};
    flex-direction: row;
    text-align: right;
    display: block;
    visibility: visible;
    opacity: 1;
    clip-path: none;
    clip: auto;
    width: 100%;
    height: auto;
    position: static;
  }
`;

export const PaymentsMethodContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  grid-column-gap: ${({ theme }) => theme.spacing.BASE_SPACING};

  @media (max-width: ${MAX_PHONE}) {
    ${visuallyHidden};
  }
`;

export const LowerFooterTitleContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
`;

export const LowerFooterPaymentsHolder = styled.div`
  ${visuallyHidden};

  @media (max-width: ${MAX_PHONE}) {
    position: relative;
    overflow: auto;
    width: auto;
    height: auto;
    clip-path: none;
    margin-top: -${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
  }
`;

export const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.new.subContent};
  ${fontSmall};
`;

export const LowerFooterTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.new.subContent};
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const LowerFooterDescription = styled(Paragraph)`
  color: ${({ theme }) => theme.new.subContent};
`;

export const FooterSVG = styled(SVG)`
  fill: ${({ theme }) => theme.new.subContent};
`;

export const PaymentSVG = styled(SVG)`
  width: 100%;
`;

export const LowerFooterContentContainer = styled.div`
  width: 50%;

  @media (max-width: ${MAX_PHONE}) {
    width: 100%;
  }
`;
