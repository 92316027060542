import React, { useState } from 'react';
import { useT, T } from 'react-polyglot-hooks';
import { SVGImages } from 'svgs';
import Bugsnag from '@bugsnag/js';
import TextInput from 'components/atoms/text-input';
import { useToast } from 'components/util/toast-context';
import LoadingSpinner from 'components/atoms/loading-spinner';
import { useAddGamertagMutation } from 'graphpl/core';
import { ResponsivePill } from 'components/atoms/button/button';
import { GamertagWrapper, Heading } from './add-gamertag-modal.styles';

const messageMap: Record<string, string> = {
  'Before you can continue, please add your PSN Username': 'playstation',
  'Before you can continue, please add your Xbox Gamertag': 'xbox',
  'Before you can continue, please add your Activision Username': 'activision',
  'Before you can continue, please add your Epic Username': 'epic',
};

const iconMap: Record<string, SVGImages> = {
  playstation: 'playstation',
  xbox: 'xbox',
  activision: 'activision',
  epic: 'epic',
};

const copyMap: Record<string, string> = {
  playstation: 'PlayStation',
  xbox: 'Xbox',
  activision: 'Activision',
  epic: 'Epic',
};

const gamertagIsMissing = (message: string): boolean => {
  if (!messageMap[message]) return false;
  return true;
};

const AddGamertagHeader = ({ message }: { message: string }) => {
  const network = messageMap[message];
  const copyTitle = copyMap[network] || '';

  return (
    <Heading>
      <T phrase="gamertag.enterYourTag" interpolations={{ tag: copyTitle }} />
    </Heading>
  );
};

type AddGamertagProps = {
  message: string;
  callback: () => void;
};

const AddGamertag = ({ callback, message }: AddGamertagProps) => {
  const translation = useT();
  const { displayToast, dismissToast } = useToast();
  const [tag, setTag] = useState('');

  const [
    updateTag,
    { loading: muationLoading, error: mutationError, called },
  ] = useAddGamertagMutation({
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (!data?.addGamertag) return;
      callback();
      dismissToast();
      displayToast({
        toastDisplayed: true,
        type: 'success',
        heading: translation('gamertag.success'),
        subtext: translation('gamertag.resubmit'),
      });
    },
    onError: (error) => {
      displayToast({
        toastDisplayed: true,
        type: 'error',
        heading: translation('gamertag.addingGamertagFailed'),
        subtext: error?.message,
      });
    },
  });

  const network = messageMap[message];
  const icon = iconMap[network];
  const copyTitle = copyMap[network];

  const onClick = async () => {
    await updateTag({
      variables: {
        network,
        tag,
      },
    });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTag(event.target.value);
  };

  const inputDisabled = muationLoading || (called && !mutationError);

  return (
    <GamertagWrapper>
      <TextInput
        id="add-gamertag"
        name="add-gamertag"
        icon={icon}
        onChange={onChange}
        value={tag}
        label={
          <T
            phrase="gamertag.enterYourTag"
            interpolations={{ tag: copyTitle }}
          />
        }
        disabled={inputDisabled}
      />
      <ResponsivePill
        data-testid="add-gamertag-confirm-button"
        onClick={onClick}
        size="large"
        disabled={inputDisabled}
      >
        {inputDisabled ? (
          <LoadingSpinner color="white" />
        ) : (
          <T phrase="confirm" />
        )}
      </ResponsivePill>
    </GamertagWrapper>
  );
};

const AddGamertagGuards = (props: AddGamertagProps) => {
  const { message } = props;

  if (!messageMap[message]) {
    Bugsnag.notify(`Bad gamertag message: ${message}`);
    return null;
  }

  return <AddGamertag {...props} />;
};

export default AddGamertagGuards;
export { AddGamertagHeader, gamertagIsMissing, messageMap };
