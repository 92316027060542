import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import Head from 'next/head';
import { AppProps } from 'next/app';
import ReactGA from 'react-ga';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
// import { datadogRum } from '@datadog/browser-rum';
// import { datadogLogs } from '@datadog/browser-logs';
import { ApolloPageProps, setCookie, useApollo } from 'helpers';
import { startBugsnag } from 'helpers/bugsnag';
import { ON_CLIENT, ON_SERVER } from 'helpers/context';
import PhrasesProvider from 'components/util/phrase-context';
import { Themes, darkTheme, lightTheme } from 'components/styles/theme';
import { UserProvider } from 'components/util/user-context';
import { ChatProvider } from 'components/util/chat-context';
import { ToastProvider } from 'components/util/toast-context';
import { GlobalProvider } from 'components/util/global-context';
import { NavigationProvider } from 'components/util/navigation-context';
import { AppProvider } from 'components/util/app-context';
import { ModalProvider } from 'components/util/modal-context';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import GlobalStyle from 'shared/global';
import routing from 'shared/routing';
import NProgress from 'nprogress';

const PingUser = dynamic(import('components/organisms/ping'), { ssr: false });
const Braze = dynamic(import('components/organisms/braze'), { ssr: false });
const Segment = dynamic(import('components/organisms/segment'), { ssr: false });
const Intercom = dynamic(import('components/organisms/intercom'), {
  ssr: false,
});
const Sift = dynamic(import('components/organisms/sift'), {
  ssr: false,
});
const Snap = dynamic(import('components/organisms/snap'), {
  ssr: false,
});

startBugsnag();

NProgress.configure({ showSpinner: false, parent: '#internal-page' });

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const handleRouteChange = () => {
  const url = window.location.pathname;
  const pageRoutingInfo = routing.filter(({ regex }) => regex.test(url));
  const eventName = pageRoutingInfo?.[0]?.analyticsName || url;
  const source = Router.router?.query?.source;

  // @ts-ignore
  window.analytics.track(`Viewed ${eventName}`, { url, source });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const AppWrapper = ({
  Component,
  pageProps,
}: AppProps<ApolloPageProps>) => {
  const {
    user = {},
    authenticated,
    isApp,
    theme,
    transparentBackground = false,
  } = pageProps;
  const [mode, setMode] = useState<Themes>(theme || 'light');
  const onSelectMode = (passedMode: Themes) => {
    setMode(passedMode);
    setCookie('pl-theme', passedMode);
    if (passedMode === 'dark') document.body.classList.add('dark-mode');
    else document.body.classList.remove('dark-mode');
  };

  useEffect(() => {
    if (ON_SERVER) return;
    // Add listener to update styles
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) =>
        onSelectMode(e.matches ? 'dark' : 'light'),
      );

    // Setup dark/light mode for the first time
    onSelectMode(
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light',
    );

    // Remove listener
    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', () => {});
    };
  }, []);

  useEffect(() => {
    ReactGA.initialize('UA-57429232-4');

    window.addEventListener('load', handleRouteChange);
    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
      window.removeEventListener('load', handleRouteChange);
    };
  }, []);

  const useMultiTheme = process.env.MULTI_THEME_ENABLED === 'true';
  const calculatedTheme = mode === 'light' ? lightTheme : darkTheme;

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.RECAPTCHA_SITE_KEY || ''}
      >
        {authenticated && <PingUser />}
        <GlobalProvider>
          <AppProvider isApp={isApp}>
            <UserProvider user={user} authenticated={authenticated}>
              <ChatProvider>
                <ThemeProvider
                  theme={useMultiTheme ? calculatedTheme : lightTheme}
                >
                  <GlobalStyle />
                  <PhrasesProvider>
                    <ToastProvider>
                      <ModalProvider>
                        <Head>
                          <meta
                            name="viewport"
                            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                          />
                        </Head>
                        <NavigationProvider
                          isApp={isApp}
                          authenticated={authenticated}
                          transparentBackground={transparentBackground}
                        >
                          <Component {...pageProps} />
                        </NavigationProvider>
                        <Braze />
                        <Segment />
                        <Intercom />
                        <Sift />
                        <Snap />
                      </ModalProvider>
                    </ToastProvider>
                  </PhrasesProvider>
                </ThemeProvider>
              </ChatProvider>
            </UserProvider>
          </AppProvider>
        </GlobalProvider>
      </GoogleReCaptchaProvider>
    </>
  );
};

const App = ({ pageProps, ...props }: AppProps<ApolloPageProps>) => {
  const apolloClient = useApollo(pageProps);

  return (
    <ApolloProvider client={apolloClient}>
      <AppWrapper pageProps={pageProps} {...props} />
    </ApolloProvider>
  );
};

export default App;

if (ON_CLIENT && process.env.NODE_ENV !== 'test') {
  // const CLIENT_TOKEN = 'pub010d95b359dab9a2a7a2c4f154baad7e';
  // const SERVICE_NAME = 'h2h-website---react';
  // datadogRum.init({
  //   applicationId: '831598fa-7cd0-44d1-8beb-2e06c0216497',
  //   clientToken: CLIENT_TOKEN,
  //   site: 'datadoghq.com',
  //   service: SERVICE_NAME,
  //   env: process.env.PL_ENV,
  //   version: process.env.VERSION_SHA || 'local',
  //   sessionSampleRate: 100,
  //   sessionReplaySampleRate: 20,
  //   trackUserInteractions: true,
  //   trackResources: true,
  //   trackLongTasks: true,
  //   defaultPrivacyLevel: 'mask-user-input',
  //   allowedTracingUrls: [
  //     /https:\/\/.*\.playerslounge\.com/,
  //     /https:\/\/.*\.playerslounge\.co/,
  //     /https:\/\/.*-pl-frontend\.vercel\.app/,
  //   ],
  // });
  // datadogLogs.init({
  //   clientToken: CLIENT_TOKEN,
  //   site: 'datadoghq.com',
  //   service: SERVICE_NAME,
  //   forwardErrorsToLogs: true,
  //   sessionSampleRate: 100,
  // });
  // datadogRum.startSessionReplayRecording();
}
