import styled, {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
  css,
} from 'styled-components';
import { fontSmaller, lightTheme } from 'components/styles';
import Palette from 'components/styles/colors';

export type PlrSizes = 'small' | 'large' | 'rectangle';

const plrSize: Record<
  PlrSizes,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  small: css`
    height: 13px;
    width: 13px;
    ${fontSmaller};
  `,
  large: css`
    height: 16px;
    width: 16px;
    ${fontSmaller};
  `,
  rectangle: css`
    height: 16px;
    width: 25px;
    ${fontSmaller};
  `,
};

export type PlrValues =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 'good'
  | 'challenging'
  | 'highlySkilled'
  | 'lowerSkilled'
  | 'slightlyLowerSkilled'
  | 'slightlyHigherSkilled';

const plrColors: Record<PlrValues, Palette> = {
  1: lightTheme.palette.matchCardPlr[1].background,
  2: lightTheme.palette.matchCardPlr[2].background,
  3: lightTheme.palette.matchCardPlr[3].background,
  4: lightTheme.palette.matchCardPlr[4].background,
  5: lightTheme.palette.matchCardPlr[5].background,
  good: lightTheme.palette.plr.goodMatchup.background,
  challenging: lightTheme.palette.plr.slightlyHigherSkilled.background,
  highlySkilled: lightTheme.palette.plr.highlySkilled.background,
  lowerSkilled: lightTheme.palette.plr.lowSkilled.background,
  slightlyLowerSkilled: lightTheme.palette.plr.slightlyLowerSkilled.background,
  slightlyHigherSkilled:
    lightTheme.palette.plr.slightlyHigherSkilled.background,
};

export type PlrTypes =
  | 'colorWhite'
  | 'colorBlue'
  | 'transparentWhite'
  | 'transparentBlue'
  | 'good'
  | 'challenging'
  | 'highlySkilled'
  | 'lowerSkilled'
  | 'slightlyLowerSkilled'
  | 'slightlyHigherSkilled';

const getTileColors = (
  type: PlrTypes,
  plr: PlrValues | null,
  theme: DefaultTheme,
) => {
  switch (type) {
    case 'colorWhite':
      return {
        // @ts-ignore
        background: plrColors[plr] || theme.palette.background.primary,
        border: 'white',
        color: 'white',
      };
    case 'colorBlue':
      return {
        // @ts-ignore
        background: plrColors[plr] || theme.palette.text.header,
        border: theme.palette.text.secondary,
        color: 'white',
      };
    case 'transparentWhite':
      return {
        background: 'transparent',
        border: 'white',
        color: 'white',
      };
    case 'transparentBlue':
      return {
        background: 'transparent',
        border: theme.palette.text.header,
        color: theme.palette.text.header,
      };
    case 'good':
      return {
        background: plrColors.good,
        border: plrColors.good,
        color: theme.palette.text.primary,
      };
    case 'challenging':
      return {
        background: plrColors.challenging,
        border: plrColors.challenging,
        color: theme.palette.text.primary,
      };
    case 'highlySkilled':
      return {
        background: plrColors.highlySkilled,
        border: plrColors.highlySkilled,
        color: theme.palette.text.primary,
      };
    case 'lowerSkilled':
      return {
        background: plrColors.lowerSkilled,
        border: plrColors.lowerSkilled,
        color: theme.palette.text.primary,
      };
    case 'slightlyLowerSkilled':
      return {
        background: plrColors.slightlyLowerSkilled,
        border: plrColors.slightlyLowerSkilled,
        color: theme.palette.text.primary,
      };
    case 'slightlyHigherSkilled':
      return {
        background: plrColors.slightlyHigherSkilled,
        border: plrColors.slightlyHigherSkilled,
        color: theme.palette.text.primary,
      };
    default:
      return {
        background: 'transparent',
        border: 'white',
        color: 'white',
      };
  }
};

export const PlrContainer = styled.div<{
  size: PlrSizes;
  bold: boolean;
  type: PlrTypes;
  plr: PlrValues | null;
}>`
  overflow: hidden;
  background-color: ${({ type, plr, theme }) =>
    getTileColors(type, plr, theme).background};
  border-radius: 100%;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: center;
  align-items: center;
  color: ${({ type, theme }) => getTileColors(type, null, theme).color};
  ${({ size }) => plrSize[size]};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  border: 1px solid #dcdcdc;
`;

export const PlrContainerLeft = styled(PlrContainer)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
  margin-right: -1px;
`;

export const PlrContainerRight = styled(PlrContainer)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
  margin-left: -1px;
`;
