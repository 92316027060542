import React from 'react';
import {
  SectionTitle,
  SectionDescription,
  RuleWrapper,
} from './match-details-section-rule.styles';

type MatchDetailsSectionRuleProps = {
  colorMode?: 'light' | 'dark';
  rule: {
    __typename?: 'Rule' | undefined;
    valueName?: string | null | undefined;
    optionDescription?: string | null | undefined;
    optionName?: string | null | undefined;
    isDefault?: boolean | null | undefined;
  };
};

const MatchDetailsSectionRule = ({
  colorMode = 'light',
  rule,
}: MatchDetailsSectionRuleProps) => {
  return (
    <RuleWrapper>
      <SectionTitle textColor={colorMode}>{rule.optionName}</SectionTitle>
      <SectionDescription textColor={colorMode}>
        {rule.optionDescription}
      </SectionDescription>
    </RuleWrapper>
  );
};

export default MatchDetailsSectionRule;
