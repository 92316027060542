import {
  AvailableIcons,
  Iconography,
  TOUCHABLE_STYLE,
  darkTheme,
  fontMContrast,
  fontXS,
  lightTheme,
} from 'components/styles';
import Palette from 'components/styles/colors';
import { useModal } from 'components/util/modal-context';
import React from 'react';
import styled, { DefaultTheme, ThemeProvider } from 'styled-components';
import { InstantMatchModal } from './instant-match-modal';
import { OpenMatchModal } from './open-match-modal';
import { DirectChallengeModal } from './direct-challenge-modal';
import { SoloMatchModal } from './solo-match-modal';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Icon = styled(Iconography)`
  padding-top: 7px;
  padding-bottom: 11px;
`;

type MatchMakingOptions =
  | 'direct-challenge'
  | 'instant-match'
  | 'open-match'
  | 'solo-match';

const avalibaleOptions: MatchMakingOptions[] = [
  'instant-match',
  'open-match',
  'direct-challenge',
  'solo-match',
];

type ButtonConfig = {
  backgroundColor: Palette;
  borderColor: Palette;
  subTextColor: Palette;
  iconColor: Palette;
};

const buttonSettings: Record<
  MatchMakingOptions,
  (theme: DefaultTheme) => ButtonConfig
> = {
  'instant-match': (theme) => ({
    backgroundColor: theme.new.information.background,
    borderColor: theme.new.information.highlight,
    subTextColor: theme.new.information.text,
    iconColor: theme.new.information.text,
  }),
  'open-match': (theme) => ({
    backgroundColor: theme.new.surface,
    borderColor: theme.new.divisions,
    subTextColor: theme.new.content,
    iconColor: theme.new.content,
  }),
  'direct-challenge': (theme) => ({
    backgroundColor: theme.new.surface,
    borderColor: theme.new.divisions,
    subTextColor: theme.new.content,
    iconColor: theme.new.content,
  }),
  'solo-match': (theme) => ({
    backgroundColor: theme.new.surface,
    borderColor: theme.new.divisions,
    subTextColor: theme.new.content,
    iconColor: Palette.twitch,
  }),
};

const ButtonWrapepr = styled.div<{ option: MatchMakingOptions }>`
  box-sizing: border-box;
  display: flex;
  height: 163px;
  width: 163px;
  border-radius: 8px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;

  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  ${TOUCHABLE_STYLE}

  padding: ${({ theme }) => theme.spacing.customSpacing('14px')};
  border: ${({ theme, option }) => buttonSettings[option](theme).borderColor}
    1px solid;
  background-color: ${({ theme, option }) =>
    buttonSettings[option](theme).backgroundColor};
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.new.content};
  ${fontMContrast}
  text-align: center;
`;

const SubTitle = styled.p<{ option: MatchMakingOptions }>`
  color: ${({ theme }) => theme.new.subContent};
  ${fontXS}
  text-align: center;
  color: ${({ theme, option }) => buttonSettings[option](theme).subTextColor};
`;

const contentMap: Record<
  MatchMakingOptions,
  {
    title: string;
    subTitle: string;
    icon: AvailableIcons;
    Component: (props: { storybookDisabled?: boolean }) => React.JSX.Element;
  }
> = {
  'instant-match': {
    title: 'Matchmaking',
    subTitle: 'We quickly find you a fair match up (most popular)',
    icon: AvailableIcons.BALANCED,
    Component: InstantMatchModal,
  },
  'open-match': {
    title: 'Public match',
    subTitle: 'Post a match in the lounge; choose your next challenger',
    icon: AvailableIcons.GLOBE,
    Component: OpenMatchModal,
  },
  'direct-challenge': {
    title: 'Direct Challenge',
    subTitle: 'Create match and send challenge to anyone',
    icon: AvailableIcons.LOCK,
    Component: DirectChallengeModal,
  },
  'solo-match': {
    title: 'Solo match',
    subTitle: 'Beat a random online opponent; Twitch required.',
    icon: AvailableIcons.TWITCH,
    Component: SoloMatchModal,
  },
};

const GenericCreateButton = ({ option }: { option: MatchMakingOptions }) => {
  const { displayModal } = useModal();
  const isDarkTheme = option === 'instant-match' || option === 'open-match';
  const theme: DefaultTheme = isDarkTheme ? darkTheme : lightTheme;
  const content = contentMap[option];

  const onClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    // The following lines prevent the event from bubbling up to the parent
    // div which will close the modal if pressed
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    displayModal({
      type: 'skinny',
      body: <content.Component />,
      modalDisplayed: true,
      displayBackButton: false,
      closeWebViewInReactNativeOnClose: true,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <ButtonWrapepr role="button" option={option} onClick={onClick}>
        <Icon
          name={content.icon}
          size={48}
          color={buttonSettings[option](theme).iconColor}
        />
        <Title>{content.title}</Title>
        <SubTitle option={option}>{content.subTitle}</SubTitle>
      </ButtonWrapepr>
    </ThemeProvider>
  );
};

export const CreateMatchOptionsModal = () => {
  const { dismissModalCurry } = useModal();
  return (
    <ModalWrapper onClick={dismissModalCurry('clickOutside')}>
      {avalibaleOptions.map((option) => (
        <GenericCreateButton key={option} option={option} />
      ))}
    </ModalWrapper>
  );
};
