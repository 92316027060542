import styled from 'styled-components';
import {
  fontMedium,
  fontMediumSmall,
  fontSmall,
  offline,
  fontMediumLarge,
  fontLargest,
  plPlusAnimation,
} from 'components/styles';

export type UsernameFontSize =
  | 'small'
  | 'mediumSmall'
  | 'medium'
  | 'mediumLarge'
  | 'largest';

const size: Record<UsernameFontSize, string> = {
  small: fontSmall,
  mediumSmall: fontMediumSmall,
  medium: fontMedium,
  mediumLarge: fontMediumLarge,
  largest: fontLargest,
};

const UsernameSpan = styled.span<{
  fontSize: UsernameFontSize;
  offline: boolean;
  animated: boolean;
  underlined: boolean;
}>`
  color: ${({ theme }) => theme.palette.text.primary};
  ${({ fontSize }) => size[fontSize]};
  text-decoration: ${({ underlined }) => underlined && 'underline'};
  ${({ animated }) => animated && plPlusAnimation};
  ${offline};
`;

export default UsernameSpan;
