import {
  DISABLED_STATE,
  TOUCHABLE_STYLE,
  fontMContrast,
} from 'components/styles';
import Palette from 'components/styles/colors';
import styled from 'styled-components';

export type LocalTheme = {
  text: Palette;
  highlight: Palette;
  background: Palette;
};

export const EntryFeePickerWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  margin-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  margin-top: -${({ theme }) => theme.spacing.customSpacing('48px')};
`;

export const AmountButton = styled.button<{
  selected: boolean;
  isInRange: boolean;
  localTheme: LocalTheme;
  disabled: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  width: ${({ theme }) => theme.spacing.customSpacing('48px')};
  height: ${({ theme }) => theme.spacing.customSpacing('48px')};
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ localTheme, isInRange }) =>
      isInRange ? 'transparent' : localTheme.text};
  border-radius: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  background-color: ${({ localTheme, selected, isInRange }) => {
    if (isInRange) {
      return 'transparent';
    }
    if (selected) {
      return localTheme.text;
    }
    return localTheme.background;
  }};
  color: ${({ localTheme, selected, isInRange }) => {
    if (isInRange) {
      return localTheme.text;
    }
    if (selected) {
      return localTheme.background;
    }
    return localTheme.text;
  }};

  ${fontMContrast}
  cursor: pointer;
  ${TOUCHABLE_STYLE}
  ${DISABLED_STATE}
`;

export const AmountBackdropWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  margin-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const AmountBackdrop = styled.div<{ columns: number }>`
  box-sizing: border-box;
  margin-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  margin-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  height: ${({ theme }) => theme.spacing.customSpacing('48px')};
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
`;

export const BackgroundAmount = styled.div<{
  selected: boolean;
  localTheme: LocalTheme;
}>`
  box-sizing: border-box;
  background-color: ${({ localTheme, selected }) =>
    selected ? localTheme.highlight : 'transparent'};
  border-top: 1px solid
    ${({ localTheme, selected }) =>
      selected ? localTheme.text : 'transparent'};
  border-bottom: 1px solid
    ${({ localTheme, selected }) =>
      selected ? localTheme.text : 'transparent'};
  height: ${({ theme }) => theme.spacing.customSpacing('48px')};
  transition: background-color 100ms, border-top 100ms, border-bottom 100ms;
`;

export const ButtonBackground = styled.div<{
  isInRange: boolean;
  localTheme: LocalTheme;
}>`
  box-sizing: border-box;
  width: ${({ theme }) => theme.spacing.customSpacing('48px')};
  height: ${({ theme }) => theme.spacing.customSpacing('48px')};
  border-radius: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  background-color: ${({ localTheme, isInRange }) =>
    isInRange ? 'transparent' : localTheme.background};
`;
