import styled, { DefaultTheme } from 'styled-components';
import SVG from 'svgs';
import {
  MAX_TABLET,
  MIN_TABLET,
  MAX_DESKTOP,
  MAX_PHONE,
} from 'components/styles';

export const ModalWrapperBack = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  z-index: ${({ theme }) => theme.depth.MODAL};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
`;

export const ModalWrapper = styled.div<{ isOpen: boolean; blur: boolean }>`
  display: table;
  width: 100%;
  height: 100%;
  background: ${({ theme, isOpen }) =>
    isOpen ? theme.modalOverlay : 'transparent'};
  transition: all 300ms;

  ${({ blur, isOpen }) => {
    if (isOpen && blur) {
      return 'backdrop-filter: blur(2px) opacity(1);';
    }
    return 'backdrop-filter: blur(2px) opacity(0);';
  }};
`;

export const HeaderWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};

  @media (min-width: ${MAX_PHONE}) {
    padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  }
`;

export type ModalType =
  | 'default'
  | 'skinny'
  | 'dark'
  | 'subscription'
  | 'dialog'
  | 'confirm'
  | 'layout';

const ModalInnerBoxType: Record<ModalType, (theme: DefaultTheme) => string> = {
  default: (theme) => `
    background-color: ${theme.new.surface};
    border: ${theme.new.divisions} 1px solid;
  `,
  skinny: (theme) => `
    background-color: ${theme.new.surface};
    max-width: ${MAX_PHONE};
    @media (min-width: ${MIN_TABLET}) {
      max-width: ${MAX_PHONE};
      max-height: 90vh;
      border: ${theme.new.divisions} 1px solid;
      border-radius: ${theme.spacing.BASE_SPACING};
    }

    @media (min-width: ${MAX_TABLET}) {
      max-width: ${MAX_PHONE};
      max-height: 90vh;
      border: ${theme.new.divisions} 1px solid;
      border-radius: ${theme.spacing.BASE_SPACING};
    }

    @media (min-width: 1000px) {
      max-width: ${MAX_PHONE};
      max-height: 90vh;
      border: ${theme.new.divisions} 1px solid;
      border-radius: ${theme.spacing.BASE_SPACING};
    }

    @media (min-width: ${MAX_DESKTOP}) {
      max-width: ${MAX_PHONE};
      max-height: 90vh;
      border: ${theme.new.divisions} 1px solid;
      border-radius: ${theme.spacing.BASE_SPACING};
    }
  `,
  dark: (theme) => `
    background-color: ${theme.palette.background.alternative};
    background: linear-gradient(${theme.instantMatch.blueGradientDark}, ${theme.instantMatch.blueGradientLight});
    border: ${theme.palette.bigMoneyMatch.border} 2px solid;
  `,
  subscription: (theme) => `
    height: max-content;
    background-color: ${theme.palette.background.alternative};
    @media (min-width: ${MAX_TABLET}) {
      width: 459px;
    }
    @media (min-width: 1000px) {
      width: 459px;
    }
  `,
  dialog: (theme) => `
    background-color: ${theme.palette.background.secondary};
    padding-left: ${theme.spacing.BASE_SPACING};
    padding-right: ${theme.spacing.BASE_SPACING};
    @media (min-width: ${MIN_TABLET}) {
      width: 343px;
      border-radius: ${theme.spacing.customSpacing('38px')};
    }
    @media (max-width: 410px) {
      margin-left: ${theme.spacing.BASE_SPACING};
      margin-right: ${theme.spacing.BASE_SPACING};
    }
    @media (max-width: ${MIN_TABLET}) {
      max-width: 343px;
      width: auto;
      border-radius: ${theme.spacing.customSpacing('38px')};
      height: max-content;
    }
  `,
  confirm: (theme) => `
    background-color: ${theme.palette.background.secondary};
    width: auto;
    height: max-content;
    border-top-left-radius: ${theme.spacing.customSpacing('8px')};
    border-top-right-radius: ${theme.spacing.customSpacing('8px')};

    @media (min-width: ${MIN_TABLET}) {
      width: 375px;
      border-radius: ${theme.spacing.customSpacing('38px')};
    }
  `,
  layout: (theme) => `
      background-color: transparent;
      border: none;
      height: max-content;
      padding: ${theme.spacing.BASE_SPACING};

      @media (min-width: ${MIN_TABLET}) {
        width: fit-content;
        height: auto;
        max-height: 90vh;
      }

      @media (min-width: ${MAX_TABLET}) {
        width: fit-content;
      }

      @media (min-width: 1000px) {
        width: fit-content;
      }

      @media (min-width: ${MAX_DESKTOP}) {
        max-width: fit-content;
        width: fit-content;
      }
  `,
};

export const ModalBox = styled.div<{ modalType: ModalType }>`
  @media (max-width: ${MIN_TABLET}) {
    vertical-align: ${({ modalType }) =>
      modalType === 'confirm' ? 'bottom' : 'middle'};
    }
  }
  display: table-cell;
  vertical-align: middle;
  position: relative;
  padding: 0;
  height: 100%;
  width: 40%;
`;

export const ModalInnerBox = styled.div<{ modalType?: ModalType }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  height: 100%;
  border-radius: 0;
  overflow: auto;
  @media (min-width: ${MIN_TABLET}) {
    width: 80vw;
    border-radius: ${({ theme, modalType }) =>
      modalType !== 'layout' && theme.spacing.customSpacing('38px')};
    height: auto;
    max-height: 90vh;
  }

  @media (min-width: ${MAX_TABLET}) {
    width: 65vw;
  }

  @media (min-width: 1000px) {
    width: 55vw;
  }

  @media (min-width: ${MAX_DESKTOP}) {
    max-width: ${({ theme }) => theme.spacing.customSpacing('640px')};
  }
  ${({ theme, modalType }) => ModalInnerBoxType[modalType || 'default'](theme)}
`;

export const ModalCloseButton = styled.button<{ modalType: ModalType }>`
  display: block;
  width: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  height: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  position: absolute;
  right: ${({ theme }) => theme.spacing.BASE_SPACING};
  top: ${({ theme }) => theme.spacing.BASE_SPACING};
  background-color: transparent;
  cursor: pointer;
  @media (max-width: ${MIN_TABLET}) {
    display: ${({ modalType }) => (modalType === 'confirm' ? 'none' : 'block')};
  }
`;

export const ModalCloseButtonSVG = styled(SVG)<{ modalType: ModalType }>`
  tabindex: -1;
  fill: ${({ theme, modalType }) =>
    modalType === 'dark' ? theme.modal.default : theme.modal.dark};
`;
