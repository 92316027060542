const debounce = <TFuncArgs extends [], TFuncReturn>(
  fn: (...args: TFuncArgs) => TFuncReturn,
  timeInMs?: number,
) => {
  let timeoutId: NodeJS.Timeout | undefined;
  return (...args: TFuncArgs) => {
    if (timeoutId) {
      clearInterval(timeoutId);
    }

    timeoutId = setInterval(() => {
      fn(...args);
      clearInterval(timeoutId);
      timeoutId = undefined;
    }, timeInMs);
  };
};

export default debounce;
