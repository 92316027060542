import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
  PropsWithChildren,
} from 'react';
import {
  // eslint-disable-next-line import/named
  useInterval,
  // eslint-disable-next-line import/named
  bindChannel,
  // eslint-disable-next-line import/named
  subscribeToChannel,
  // eslint-disable-next-line import/named
  unbindChannel,
  // eslint-disable-next-line import/named
  initPusherSocket,
} from 'helpers';
import { UserFragment, useGetCurrentUserLazyQuery } from 'graphpl/core';

type UserProviderProps = {
  user: UserFragment;
  authenticated: boolean;
};

type UserContextType = {
  user: UserFragment | null;
  authenticated: boolean;
  setUser: (user: UserFragment) => void;
  setUserRaw: React.Dispatch<React.SetStateAction<UserFragment>>;
};

const POLL_INTERVAL_IN_MS = 60 * 1000;
const DISABLED_POLLING = 3600 * 1000;

export const UserContext = createContext<UserContextType>({
  user: null,
  authenticated: false,
  setUser: () => {},
  setUserRaw: () => {},
});

const orderObject = (rawObject: UserFragment) =>
  Object.keys(rawObject)
    .sort()
    .reduce(
      (accumulator, key) => ({
        ...accumulator,
        [key]: rawObject[key as keyof UserFragment],
      }),
      {},
    );

export const UserProvider = ({
  user,
  authenticated,
  children,
}: PropsWithChildren<UserProviderProps>) => {
  const [userState, setUserRaw] = useState<UserFragment>(user);

  const updateUser = useCallback(
    (update: UserFragment | null) => {
      const newState = orderObject({ ...userState, ...update });
      if (JSON.stringify(newState) === JSON.stringify(userState)) return;
      setUserRaw(newState);
    },
    [userState],
  );

  const setUser = (newUser: UserFragment) =>
    setUserRaw((prev) => {
      if (prev === null) return newUser;
      const newState = orderObject({ ...prev, ...newUser });
      if (JSON.stringify(newState) === JSON.stringify(prev)) return prev;
      return newState;
    });

  const [queryCurrentUser] = useGetCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data.currentUser) return;
      updateUser(data.currentUser);
    },
  });

  const pollOptions: [() => void, number] = authenticated
    ? [queryCurrentUser, POLL_INTERVAL_IN_MS]
    : [() => {}, DISABLED_POLLING];

  useInterval(...pollOptions);

  useEffect(() => {
    initPusherSocket();
  }, []);

  useEffect(() => {
    if (authenticated) {
      queryCurrentUser();
    }
  }, [authenticated]);

  useEffect(() => {
    if (!userState) return;
    if (Object.keys(userState).length === 0) return;

    const channelName = `global-${userState.id}`;
    const channel = subscribeToChannel(channelName);

    if (!channel) return;

    bindChannel<string>(channel, 'balance-update', (info) => {
      let parsedInfo;
      if (typeof info === 'string') {
        parsedInfo = JSON.parse(info || '{}');
      } else {
        parsedInfo = info;
      }
      const { balance: updatedBalance } = parsedInfo;
      const obj = {
        balance: updatedBalance,
      };
      updateUser(obj);
    });

    bindChannel<string>(channel, 'wallet-update', (info) => {
      if (typeof info !== 'string') {
        return;
      }
      if (JSON.parse(info || '{}').refetch) {
        queryCurrentUser();
      }
    });

    const cleanup = () => {
      unbindChannel(channel, 'balance-update');
      unbindChannel(channel, 'wallet-update');
    };

    return cleanup;
  }, [userState?.id]);

  return (
    <UserContext.Provider
      value={{ user: userState, authenticated, setUser, setUserRaw }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
