import React, { FC, SVGProps } from 'react';
import { useTheme } from 'styled-components';
import { ValidColor } from '../colors';

import { MaterialIcons, standardIconComponentMap } from './standard-icons';
import { SocialIcons, socialIconsComponentMap } from './social-icons';

export const AvailableIcons = {
  ...MaterialIcons,
  ...SocialIcons,
};

export type AvailableIcons = MaterialIcons | SocialIcons;

const componentMap: Record<AvailableIcons, FC<SVGProps<SVGElement>>> = {
  ...socialIconsComponentMap,
  ...standardIconComponentMap,
};

export type IconographyProps = {
  name: AvailableIcons;
  color?: ValidColor;
  size?: number;
  viewBox?: string;
};

export const Iconography = ({
  name,
  color,
  size = 24,
  viewBox = '0 0 24 24',
  ...rest
}: IconographyProps) => {
  const Component = componentMap[name];
  const theme = useTheme();

  const actualColor = color || theme.new.content;
  if (process.env.NODE_ENV === 'test') return <div {...rest} />;
  return (
    <Component
      width={size}
      height={size}
      fill={actualColor}
      viewBox={viewBox}
      {...rest}
    />
  );
};
