import styled from 'styled-components';
import { fontMediumSmall } from 'components/styles';

export const Separator = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledHR = styled.hr`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.background.primary};
  display: flex;
  flex-grow: 1;
`;

export const SeparatorLabel = styled.span`
  ${fontMediumSmall};
  padding: 0 ${({ theme }) => theme.spacing.customSpacing('5px')};
  color: ${({ theme }) => theme.palette.text.header};
`;
