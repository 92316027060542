import styled from 'styled-components';
import {
  DISABLED_STATE,
  TOUCHABLE_STYLE,
  fontL,
  fontMContrast,
  fontS,
  fontXL,
  fontXXS,
} from 'components/styles';

export const ModalWrapper = styled.div`
  background-color: ${({ theme }) => theme.new.surface};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing.DOUBLE_BASE_SPACING};
`;

export const TitleWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const HeaderText = styled.h2`
  ${fontXL}
  color: ${({ theme }) => theme.new.content};
  padding-bottom: 1px;
`;

export const SubHeaderText = styled.p`
  ${fontS}
  color: ${({ theme }) => theme.new.content};
`;

export const HeaderIconWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  cursor: pointer;
  ${TOUCHABLE_STYLE}
`;

export const SubHeader = styled.h3`
  ${fontL}
  color: ${({ theme }) => theme.new.content};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const InputWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const BottomWrapper = styled.div`
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  width: 100%;
`;

export const ServiceFeeText = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING};
  color: ${({ theme }) => theme.new.subContent};
  ${fontXXS}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  gap: ${({ theme }) => theme.spacing.DOUBLE_BASE_SPACING};
  padding-bottom: ${({ theme }) => theme.spacing.customSpacing('44px')};
`;

export const CheckboxRow = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.BASE_SPACING};

  cursor: pointer;
  ${TOUCHABLE_STYLE}
  ${DISABLED_STATE}
`;

export const CheckboxToggleWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
`;

export const CheckboxTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  gap: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
`;

export const CheckboxName = styled.h4`
  ${fontMContrast}
  color: ${({ theme }) => theme.new.content};
`;

export const CheckboxDescription = styled.p`
  ${fontXXS}
  color: ${({ theme }) => theme.new.content};
`;

export const EmptyServiceFeeText = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const EntryFeeWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const EntryFeeErrorText = styled.p`
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-top: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
  color: ${({ theme }) => theme.new.error.background};
  height: ${({ theme }) => theme.spacing.customSpacing('12.79px')};
  ${fontXXS}
`;
