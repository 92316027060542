import styled, { css } from 'styled-components';
import SVG from 'svgs';
import { fontMediumSmall, fontSmaller } from 'components/styles';

export const Input = styled.input<{ active: boolean }>`
  height: ${({ theme }) => theme.spacing.BASE_SPACING};
  width: 100%;
  border-width: 0px;
  height: 100%;
  border-color: transparent;
  background-color: transparent;
  outline: 0px;
  padding: 0px;

  ${fontMediumSmall}
  font-weight: 400;
  color: ${({ theme }) => theme.new.content};
  caret-color: ${({ theme }) => theme.new.information.background};
  margin-bottom: ${({ active, value, theme }) =>
    active || value ? theme.spacing.HALF_BASE_SPACING : 0};
`;

export type TextInputBackground = 'primary' | 'secondary';

export const DefaultWrap = styled.label<{
  background: TextInputBackground;
  icon: boolean;
  disabled: boolean;
}>`
  height: ${({ theme }) => theme.spacing.customSpacing('48px')};
  background-color: ${({ theme, background }) =>
    background === 'primary' ? theme.new.background : theme.new.surface};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${({ icon, theme }) =>
    icon ? theme.spacing.BASE_SPACING : theme.spacing.BASE_SPACING_AND_HALF};

  border-radius: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  border: ${({ theme }) => theme.spacing.customSpacing('1px')} solid
    ${({ theme }) => theme.new.divisions};

  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
`;

export const DefaultLabel = css`
  ${fontMediumSmall}
  font-weight: 400;
  color: ${({ theme }) => theme.new.content};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const InputBox = styled.div<{
  isTypePassword: boolean;
  icon: boolean;
  active: boolean;
  value: boolean;
}>`
  display: flex;
  flex-direction: ${({ active, value }) =>
    active || value ? 'column' : 'row'};
  align-items: center;
  width: 100%;
  padding-left: ${({ icon, theme }) =>
    icon ? theme.spacing.customSpacing('12px') : '0px'};
  padding-right: ${({ isTypePassword, theme }) =>
    isTypePassword ? 0 : theme.spacing.BASE_SPACING};
  ${'' /* above is for password managers icon spacing for things like email */}
  white-space: nowrap;
`;

export const StyledSVG = styled(SVG)`
  fill: ${({ theme }) => theme.new.subContent};

  :active {
    fill: ${({ theme }) => theme.new.content};
  }
`;

export const DefaultHelperMessage = styled.div<{
  active: boolean;
  success: boolean;
  error: boolean;
}>`
  height: ${({ theme }) => theme.spacing.customSpacing('12px')};
  ${fontSmaller}
  color: ${({ theme }) => theme.new.subContent};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-top: ${({ theme }) => theme.spacing.customSpacing('2px')};
`;

export const PasswordButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 48px;

  background-color: transparent;
  outline: none;
  cursor: pointer;
  fill: ${({ theme }) => theme.new.subContent};

  :active {
    fill: ${({ theme }) => theme.new.content};
  }
`;

export const InputComponentWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;
