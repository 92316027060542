import { css } from 'styled-components';

export const TOUCHABLE_STYLE = css`
  opacity: 1;
  transition: 0.3s;

  :hover:active:not([disabled]) {
    opacity: 0.2;
  }
  :hover:not([disabled]) {
    opacity: 0.6;
  }
`;
