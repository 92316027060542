import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import Switch from 'react-switch';
import { usePrevious } from 'helpers';
import StyledCheckbox from './checkbox.styles';

type CheckboxProps = {
  defaultChecked?: boolean;
  variant?: 'checkbox' | 'toggle';
  id: string;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

const Checkbox = ({
  defaultChecked,
  variant = 'checkbox',
  id,
  onChange,
  disabled,
}: CheckboxProps) => {
  const theme = useTheme();
  const prevChecked = usePrevious(defaultChecked);
  const [isChecked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    if (prevChecked && !defaultChecked && isChecked) {
      setChecked(false);
    }
  }, [defaultChecked, isChecked, prevChecked]);

  const handleSwitchChange = (value: boolean) => {
    setChecked(value);
    onChange?.(value);
  };

  const handleInputChange = () => {
    const oldValue = isChecked || defaultChecked || false;
    const value = !oldValue;
    setChecked(value);
    onChange?.(value);
  };

  if (variant === 'toggle') {
    return (
      <Switch
        onChange={(value, e) => {
          e.stopPropagation();
          handleSwitchChange(value);
        }}
        checked={Boolean(isChecked ?? defaultChecked)}
        checkedIcon={false}
        offColor={theme.switcher.off}
        onColor={theme.switcher.on}
        uncheckedIcon={false}
        handleDiameter={24}
        height={32}
        width={57}
        id={id}
        data-testid={`${id}-checkbox`}
        disabled={disabled}
      />
    );
  }

  const getIsChecked = () => {
    if (!defaultChecked) {
      return false;
    }
    return true;
  };

  return (
    <StyledCheckbox
      handleInputChange={handleInputChange}
      checked={getIsChecked() || isChecked}
      id={id}
      disabled={disabled}
    />
  );
};

export default Checkbox;
