import { MIN_TABLET } from 'components/styles';
import { fontMedium } from 'components/styles/typography';
import styled from 'styled-components';
import Palette from 'components/styles/colors';
import { Pill } from '../button';
import Link from '../link';

export const ButtonContainer = styled.div<{ undercarriage: boolean }>`
  height: min-content;
  display: flex;
  justify-content: center;
  margin-top: auto;
  gap: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};
  ${({ undercarriage }) => (undercarriage ? 'padding-bottom: 0;' : '')}
`;

export const NavigationButton = styled(Pill)`
  flex: 1 1 0px;
  ${fontMedium}
  font-weight: 900;

  &:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  }

  @media (min-width: ${MIN_TABLET}) {
    width: ${({ theme }) => theme.spacing.customSpacing('152px')};
    max-width: ${({ theme }) => theme.spacing.customSpacing('205px')};
  }
`;

export const StyledLink = styled(Link)`
  flex: 1 1 0px;
  flex-basis: 50%;
  ${fontMedium}
  display: flex;

  &:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  }

  @media (min-width: ${MIN_TABLET}) {
    width: ${({ theme }) => theme.spacing.customSpacing('205px')};
    max-width: ${({ theme }) => theme.spacing.customSpacing('205px')};
  }
`;

const PositionIndicatorContainer = styled.div`
  height: min-content;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.HALF_BASE_SPACING}
    ${({ theme }) => theme.spacing.HALF_BASE_SPACING}
    ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const MobilePositionIndicatorContainer = styled(
  PositionIndicatorContainer,
)`
  display: flex;

  @media (min-width: ${MIN_TABLET}) {
    display: none;
  }
`;

export const DesktopPositionIndicatorContainer = styled(
  PositionIndicatorContainer,
)`
  display: none;

  @media (min-width: ${MIN_TABLET}) {
    display: flex;
  }
`;

export const PositionIndicator = styled.div<{ itemSelected?: boolean }>`
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing.customSpacing('12px')};
  height: ${({ theme }) => theme.spacing.customSpacing('12px')};
  background-color: ${({ theme, itemSelected }) =>
    itemSelected
      ? theme.slideshow.position.mobile.selected
      : theme.slideshow.position.mobile.unselected};

  &:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  }

  @media (min-width: ${MIN_TABLET}) {
    background-color: ${({ theme, itemSelected }) =>
      itemSelected
        ? theme.slideshow.position.desktop.selected
        : theme.slideshow.position.desktop.unselected};
  }
`;

export const SlideshowContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 2fr 1fr;

  @media (min-width: ${MIN_TABLET}) {
    display: block;
  }
`;

export const ContentContainer = styled.div<{
  backgroundColor?: Palette | string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.games.madden};

  @media (min-width: ${MIN_TABLET}) {
    height: ${({ theme }) => theme.spacing.customSpacing('335px')};
    justify-content: space-between;
  }
`;

export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${MIN_TABLET}) {
    min-height: ${({ theme }) => theme.spacing.customSpacing('220px')};
  }
`;

export const DesktopContentSpacer = styled.div`
  display: none;

  @media (min-width: ${MIN_TABLET}) {
    display: block;
  }
`;

export const UndercarriageContainer = styled.div`
  height: min-content;
  display: flex;
  justify-content: center;
  margin-top: auto;
`;
